import { useContext, useEffect } from 'react';
import { Alert, Button, Link, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import authZConfig from '../../authZ/authZ.config';
import { useAxiosApi } from '../../axios/useAxiosApi';
import useAxiosGateway from '../../axios/useAxiosGateway';
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useYupValidationResolver from '../../validation/useYupValidationResolver';
import { getCurrentUser, setCurrentUser } from '../../authZ/authZ';
import GlobalStateContext from '../../GlobalStateContext';

const defaultValues = {
    email: '',
    password: ''
};

const validationSchema = yup.object({
    email: yup.string().required("Please enter an email.").email("Please enter a valid email."),
    password: yup.string().required("Please enter a password.")
});

const DefaultLogin = () => {
    const { setAppUserId, setLoggedInUser } = useContext(GlobalStateContext);
    const navigate = useNavigate();

    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues } = methods;

    const [{ data: loginData, loading: loginLoading, error: loginError }, executeLogin] = useAxiosGateway(
        {
            url: '/login',
            method: 'POST'
        },
        {
            manual: true
        }
    );

    const [{ data: getUserData, loading: getUserLoading, error: getUserError }, executeGetUser] = useAxiosApi(
        {
            url: '/users/me?fields=email,first_name,last_name,id,suffix',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const [{ data: getUserId, loading: getUserIdLoading, error: getUserIdError }, executeGetUserId] = useAxiosApi(
        {
            url: '/items/Users',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    useEffect(() => {
        if (loginData != null) {
            executeGetUser();
        }
    }, [loginData]);

    useEffect(() => {
        if (getUserData) {
            setCurrentUser(getUserData);
            setLoggedInUser(getCurrentUser());
            executeGetUserId(
                {  
                    params: { 
                        fields: "Id", 
                        "filter[DirectusUserId][_eq]": getUserData.data.id 
                    }
                }
            );
            
        }
    }, [getUserData]);

    useEffect(() => {
        if (getUserId) {
            localStorage.setItem(authZConfig.localStorageAppUserId, getUserId.data[0].Id);
            setAppUserId(parseInt(localStorage.getItem(authZConfig.localStorageAppUserId)));
            navigate(authZConfig.loginSuccessRoute);
        }
    }, [getUserId]);
    
    function login(credentials) {
        executeLogin({
            data: {
                email: credentials.email,
                password: credentials.password
            }
        })
    }

    return (
        <form className="loginForm" onSubmit={ handleSubmit(login) }>
            <Stack spacing={2}>
                <FormTextField
                    name="email" 
                    control={control} 
                    label="Email" 
                    type="text"
                    variant="outlined"
                    error={errors?.email != null}
                    helperText={errors?.email?.message}
                />

                <FormTextField
                    name="password" 
                    control={control} 
                    label="Password"
                    type="password"
                    variant="outlined"
                    error={errors?.password != null}
                    helperText={errors?.password?.message}
                />
                <>{ loginError?.response == null && loginError?.message && <Alert severity="error">{loginError?.message}</Alert> }</>
                <>{ loginError?.response?.data && <Alert severity="error">{loginError?.response?.data}</Alert> }</>
                <>{ getUserError?.response?.data && <Alert severity="error">{getUserError?.response?.data}</Alert> }</>
                <>
                    <LoadingButton loading={loginLoading || getUserLoading} type="submit" variant="contained">Login</LoadingButton>
                    <Link component={RouterLink} to={{pathname: "/forgotPassword" }} underline="none">Forgot Password?</Link>
                    { 
                        authZConfig.selfRegistrationEnabled && 
                        <Button 
                            component={RouterLink}
                            to="/register"
                            color="secondary"
                            variant="contained"
                        >Create Account</Button>
                    }
                </>
            </Stack>
        </form> 
    );
  };
  
  export default DefaultLogin;