import axios from 'axios';
import { makeUseAxios } from 'axios-hooks'
import authZConfig from '../authZ/authZ.config';

const gateway = axios.create({
    baseURL: authZConfig.gatewayBaseUrl,
    withCredentials: true
});

const useAxiosGateway = makeUseAxios({
    axios: gateway,
    cache: false
})

export default useAxiosGateway;
