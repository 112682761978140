import { Card, CardContent, CardHeader, Chip, Grid, Link, Typography } from "@mui/material";
import { useContext } from "react";
import ItemsDashboardContext from './ItemsDashboardContext';

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

const ItemReadOnly = () => { 
    const {selectedItem} = useContext(ItemsDashboardContext);

    return (
    <Grid container direction="column" spacing={2}>
        <Grid container item spacing={1} alignItems="center">
            { selectedItem.Groups.length > 0 &&
                <Grid item>
                    Shared with
                </Grid>
            }  
            { selectedItem.Groups.length == 0 &&
                <Grid item>
                    <Typography variant="p" sx={{fontStyle: 'italic'}}>*Not sharing with any wells.*</Typography>
                </Grid>
            }
            { selectedItem.Groups.map((itemGroup) => (
                <Grid key={itemGroup.GroupId.Id} item>
                    <Chip label={itemGroup.GroupId.Name} />
                </Grid>
            ))
            }
        </Grid>
        {selectedItem.Description && 
            <Grid item>
                Description: {selectedItem.Description}
            </Grid>
        }
        <Grid item>
            Quantity: {selectedItem.Quantity}
        </Grid>
        <Grid item>
            Stores
        </Grid>
        <Grid item container spacing={2}>
        { selectedItem.Stores.map((itemStore) => (
                <Grid item key={itemStore.StoreId.Id} xs={12} sm={6} md={4} lg={3}>
                    <Card className="store-card">
                        <CardContent>
                            <Grid direction="column" container spacing="5">
                                <Grid item>
                                    {itemStore.Url && <Link href={itemStore.Url} color="inherit" target="_blank" variant="h6">{itemStore.StoreId.Name}</Link>}
                                    {!itemStore.Url && <Typography variant="h6">{itemStore.StoreId.Name}</Typography>}
                                </Grid>
                                <Grid item>
                                    {itemStore.Price && <Typography variant="p">{currencyFormatter.format(itemStore.Price)}</Typography> }
                                    {!itemStore.Price && <Typography variant="p" sx={{fontStyle: 'italic'}}>No price listed.</Typography> }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))
        }
        { selectedItem.Stores.length == 0 &&
            <Grid item>
                <Typography variant="p" sx={{fontStyle: 'italic'}}>No stores have been added.</Typography>
            </Grid>
        }
        </Grid>
    </Grid>
  );
};
  
export default ItemReadOnly;