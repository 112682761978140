import { Alert, Button, Stack } from '@mui/material';
import FacebookIcon from './provider-logins/facebook-icon.png'; 
import GoogleIcon from './provider-logins/google-icon.png'; 
import MicrosoftIcon from './provider-logins/microsoft-icon.png'; 
import YahooIcon from './provider-logins/yahoo-icon.png'; 
import authZConfig from '../../authZ/authZ.config';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
const ProviderLogin = () => {

    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);

    useEffect(() => {
      if (searchParams) {
        setError(searchParams.get('error'));
      }
    }, []);

    const handleClick = () => {
      setError(null);
    }

    return (
      <Stack spacing={2}>
        { error != null && <Alert severity="error">{error}</Alert> }
        { authZConfig.facebookLoginEnabled && <Button onClick={handleClick} href={authZConfig.gatewayBaseUrl + '/login/facebook'} variant="contained" color="logo" startIcon={<img src={FacebookIcon} height="24.5" />}>Login with Facebook</Button>}
        { authZConfig.googleLoginEnabled && <Button onClick={handleClick}  href={authZConfig.gatewayBaseUrl + '/login/google'} variant="contained" color="logo" startIcon={<img src={GoogleIcon} height="25" />}>Login with Google</Button>}
        { authZConfig.microsoftLoginEnabled && <Button onClick={handleClick}  href={authZConfig.gatewayBaseUrl + '/login/microsoft'} variant="contained" color="logo" startIcon={<img src={MicrosoftIcon} height="24.5" />}>Login with Microsoft</Button>}
        { authZConfig.yahooLoginEnabled && <Button onClick={handleClick}  href={authZConfig.gatewayBaseUrl + '/login/yahoo'} variant="contained" color="logo" startIcon={<img src={YahooIcon} height="24.5" />}>Login with Yahoo</Button>}
      </Stack>
    );
}

export default ProviderLogin;