import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import authZConfig from '../../authZ/authZ.config';
import { useAxiosApi } from '../../axios/useAxiosApi';
import GlobalStateContext from '../../GlobalStateContext';
import { getCurrentUser, setCurrentUser } from '../../authZ/authZ';

const Callback = () => {
    const { setAppUserId, setLoggedInUser } = useContext(GlobalStateContext);
    const navigate = useNavigate();

    const [{ data: getUserData, loading: getUserLoading, error: getUserError }, executeGetUser] = useAxiosApi(
        {
            url: '/users/me?fields=email,first_name,last_name,id',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const [{ data: getUserIdData, loading: getUserIdLoading, error: getUserIdError }, executeGetUserId] = useAxiosApi(
        {
            url: '/items/Users',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    useEffect(() => {
        executeGetUser();
    }, []);

    useEffect(() => {
        if (getUserData) {
            setCurrentUser(getUserData);
            setLoggedInUser(getCurrentUser());
            executeGetUserId(
                {  
                    params: { 
                        fields: "Id", 
                        "filter[DirectusUserId][_eq]": getUserData.data.id 
                    }
                }
            );
        }
    }, [getUserData]);

    useEffect(() => {
        if (getUserIdData) {
            localStorage.setItem(authZConfig.localStorageAppUserId, getUserIdData.data[0].Id);
            setAppUserId(parseInt(localStorage.getItem(authZConfig.localStorageAppUserId)));
            navigate(authZConfig.loginSuccessRoute);
        }
    }, [getUserIdData]);
    
    return (
        <>
        </>
    );
};

export default Callback;