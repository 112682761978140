import * as yup from 'yup';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Container, Stack } from '@mui/material';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { Link } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {ReactComponent as Logo } from '../../logo.svg'; // Replace with Application Logo

const defaultValues = {
    password: '',
    confirmPassword: ''
};

const validationSchema = yup.object({
    password: yup.string().required('Please enter a password.'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match!')
    .required('Please confirm password.')
});

const PasswordReset = () => {

    const location = useLocation();
    const params = new URLSearchParams(location.search)

    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues } = methods;
    
    const [{ data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError, response: resetPasswordResponse }, executeResetPassword] = useAxiosApi(
        {
            url: '/auth/password/reset',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    function resetPassword(form) {
        executeResetPassword({
            data: {
                token: params.get('token'),
                password: form.password
            }
        })
    }

    return (
        <Container maxWidth="xs" sx={{marginTop: 5}}>
            <Logo className="logo" />
            <h1>Reset Password</h1>
            {resetPasswordResponse?.status != 204 && <form className="resetPassword" onSubmit={ handleSubmit(resetPassword)}>
                <Stack spacing={2}>
                    <FormTextField
                            name="password" 
                            control={control} 
                            label="Password" 
                            type="password"
                            variant="outlined"
                            error={errors?.password != null}
                            helperText={errors?.password?.message}
                    />

                    <FormTextField
                            name="confirmPassword" 
                            control={control} 
                            label="Confirm Password" 
                            type="password"
                            variant="outlined"
                            error={errors?.confirmPassword != null}
                            helperText={errors?.confirmPassword?.message}
                    />
                    <LoadingButton loading={resetPasswordLoading} type="submit" variant="contained">Send</LoadingButton>
                </Stack>
            </form>}
            {resetPasswordError?.response?.data.errors.map((error) => {
                return <Alert severity="error">{ error.message }</Alert>;
            })}
            {resetPasswordError?.response?.data.errors == null && resetPasswordError?.response?.data && <Alert severity="error">{JSON.stringify(resetPasswordError?.response?.data)}</Alert>}
            {resetPasswordResponse?.status == 204 && <Alert severity="success">Password reset. Return to <Link component={RouterLink} to={{pathname: "/login" }} underline="none">login</Link>.</Alert> }
        </Container>
        
    );
};

export default PasswordReset;