import { useContext, useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography } from '@mui/material';
import { logout } from '../axios/useAxiosApi';
import { getCurrentUser } from '../authZ/authZ';
import SettingsIcon from '@mui/icons-material/Settings';
import { AccountCircle as AccountCircleIcon, Close as CloseIcon, Logout as LogoutIcon, Menu as MenuIcon, People as PeopleIcon, List as ListIcon, FactCheck as FactCheckIcon} from '@mui/icons-material';
import { teal } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom'
import GlobalStateContext from '../GlobalStateContext';
import { Link as RouterLink } from 'react-router-dom';
import {ReactComponent as Logo } from '../logo.svg'; // Replace with Application Logo
import {ReactComponent as WellIcon} from '../well-icon.svg';

const Layout = () => {
    const navigate = useNavigate();
    const { loggedInUser, setLoggedInUser } = useContext(GlobalStateContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setLoggedInUser(getCurrentUser());
    }, []);

    return (
        <>
            {
            loggedInUser && 
            <AppBar position="static">
                <Toolbar variant="dense" sx={{display: {xs: 'none', md: 'inline-flex'}}}>
                    <Logo className="logo app-bar-logo" />
                    <Box sx={{ flexGrow: 1 }} />
                    <Tooltip title="Items Dashboard">
                        <IconButton aria-label="itemsDashboard" component={RouterLink} to={{pathname: "/home" }} >
                            <ListIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Shared Items">
                        <IconButton aria-label="sharedItems" component={RouterLink} to={{pathname: "/items/shared" }} >
                            <FactCheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Wells (Groups)">
                        <IconButton aria-label="wells" component={RouterLink} to={{pathname: "/wells" }} >
                            <WellIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="User Management">
                        <IconButton aria-label="manageUsers" component={RouterLink} to={{pathname: "/users/manage" }} >
                            <PeopleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Settings">
                        <IconButton 
                            aria-label="settings" 
                            sx={{ marginRight: 0.5 }} 
                            onClick={handleClick} 
                            aria-controls={open ? 'settings-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                    
                    <Typography component="div" sx={{ marginRight: 2}}>{ loggedInUser.first_name } { loggedInUser.last_name }</Typography>
                    
                    <Button color="primary" variant="contained" onClick={ logout }>Logout</Button>
                </Toolbar>
                <Toolbar variant="dense" sx={{ display: { xs: 'inline-flex', sm: 'inline-flex', md: 'none' } }}>
                    <Box sx={{ flexGrow: 1 }} />
                    <Logo className="logo app-bar-logo" />
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={ () => setOpenDrawer(true) }>
                        <MenuIcon />
                    </IconButton>
                </Toolbar>

                <Drawer
                    anchor={"right"}
                    open={openDrawer}
                    onClose={ () => setOpenDrawer(false)}
                >
                    <List sx={{ width: 350}}>
                        <ListItem>
                            <Box sx={{ flexGrow: 1 }} />
                            <AccountCircleIcon />
                            <ListItemText>
                                <Typography component="div">{ loggedInUser.first_name } { loggedInUser.last_name }</Typography>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem onClick={() => setOpenDrawer(false)} className="mobile-menu-link" component={RouterLink} to={{pathname: null }}>
                            <ListItemIcon>
                                <CloseIcon />
                            </ListItemIcon>
                            <ListItemText primary="CLOSE"></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className="mobile-menu-link" component={RouterLink} to={{pathname: "/home" }} onClick={() => setOpenDrawer(false)}>
                            <ListItemIcon>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText primary="ITEMS DASHBOARD"></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className="mobile-menu-link" component={RouterLink} to={{pathname: "/items/shared" }} onClick={() => setOpenDrawer(false)}>
                            <ListItemIcon>
                                <FactCheckIcon />
                            </ListItemIcon>
                            <ListItemText primary="SHARED ITEMS"></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className="mobile-menu-link" component={RouterLink} to={{pathname: "/wells" }} onClick={() => setOpenDrawer(false)}>
                            <ListItemIcon>
                                <WellIcon />
                            </ListItemIcon>
                            <ListItemText primary="WELLS (GROUPS)"></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className="mobile-menu-link" component={RouterLink} to={{pathname: "/users/manage" }} onClick={() => setOpenDrawer(false)}>
                            <ListItemIcon>
                                <PeopleIcon />
                            </ListItemIcon>
                            <ListItemText primary="USER MANAGEMENT"></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem className="mobile-menu-link" component={RouterLink} to={{pathname: "/account/settings" }} onClick={() => setOpenDrawer(false)}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary="ACCOUNT SETTINGS"></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem onClick={logout} className="mobile-menu-link" component={RouterLink} to={{pathname: null }}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="LOGOUT"></ListItemText>
                        </ListItem>
                        <Divider />
                    </List>
                </Drawer>
            </AppBar>
            }
            <Menu
                anchorEl={anchorEl}
                id="settings-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5                        
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                sx={{ "& .MuiPaper-root": {
                    backgroundColor: teal[500],
                    color: 'black'
                }}}
            >
                <MenuItem onClick={() => navigate('/account/settings')}>
                    Account settings
                </MenuItem>
            </Menu>
            <Outlet />
        </>
  )
};

export default Layout;