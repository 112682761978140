import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, Chip, Grid, InputAdornment, MenuItem, Snackbar, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { FormAutoComplete, FormSelect, FormTextField } from '../../form/MuiHookForm';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import ItemsDashboardContext from './ItemsDashboardContext';

const defaultValues = {
    name: '',
    description: '',
    quantity: 1,
    store: '',
    price: '',
    url: '',
    wells: []
};

const transformStoreObject = function(store, originalStore) {
    const transformedStore = typeof store === 'string' ? originalStore : originalStore?.Name;
    return transformedStore;
}

const validationSchema = yup.object({
    name: yup.string().required('Please enter an item name.').max(200, 'Must be no more than 200 characters.'),
    description: yup.string().max(200, 'Must be no more than 200 characters.'),
    quantity: yup.number().min(1, 'Must be one or more.').typeError("Must be an integer."),
    store: yup.string().transform(transformStoreObject).max(50, 'Must be no more than 50 characters.').when(['price', 'url'], {
        is: (price, url) => price !== '' || url !== '',
        then: yup.string().required('Store is required.')
    }),
    price: yup.string().matches(/^$|^(0|[1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?$/gm, 'Must be a US Dollar amount.'),
    url: yup.string().matches(/^$|(https|http):\/\/.*$/gm, 'Must start with http:// or https://')
});

const ItemAdd = () => {
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues, setValue, reset } = methods;
    const { itemUserId, stores, setStores, wells, setItemAdded } = useContext(ItemsDashboardContext);
    
    const [{ data: createItemData, loading: createItemLoading, error: createItemError, response: createItemResponse }, executeCreateItem] = useAxiosApi(
        {
            url: '/items/Items',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    const [{ data: getStoresData, loading: getStoresLoading, error: getStoresError, response: getStoresResponse }, executeGetStores] = useAxiosApi(
        {
            url: '/items/Stores',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const getStores = () => {
        executeGetStores({
            params: {
                "sort[]": "Name",
            }
        })
    }

    useEffect(() => {
        getStores();
    }, [])
    
    useEffect(()=> {
        if(getStoresData != null) {
            setStores(getStoresData.data)
        }
    },[getStoresResponse])

    const createItem = (form, matchedStore) => {
        const store = buildStoreData(form, matchedStore);
        const selectedWells = form.wells.map((selectedWell) => ({ GroupId: selectedWell.GroupId.Id }));

        let createData = null;
        if(store != null) {
            createData = {
                Name: form.name,
                Description: form.description,
                Quantity: form.quantity,
                Users: [
                    {
                        RoleId: 1, // Give owner role id for item creation
                        UserId: itemUserId
                    }
                ],
                Stores: [
                    store
                ],
                Groups: selectedWells
            }
        } else {
            createData = {
                Name: form.name,
                Description: form.description,
                Quantity: form.quantity,
                Users: [
                    {
                        RoleId: 1, // Give owner role id for item creation
                        UserId: itemUserId
                    }
                ],
                Groups: selectedWells
            }
        }

        executeCreateItem({
            data: createData
        })
    }

    useEffect(() => {
        if (wells) {
            setValue('wells', wells)
        }
    }, [wells])

    const handleCreateItem = function(form) {
        const matchingStores = stores.filter((store) => store.Name.toUpperCase() === form.store.toUpperCase())
        const matchedStore = matchingStores.length > 0 ? matchingStores[0] : null;
        createItem(form, matchedStore);
    }

    const buildStoreData = (form, matchedStore) => {
        
        const price = form.price == '' ? null : form.price;
        const url = form.url == '' ? null : form.url;

        if (matchedStore != null) {
            return {
                StoreId: matchedStore.Id,
                Price: price,
                Url: url
            }
        } else if(form.store != '') {
            return {
                Price: price,
                Url: url,
                StoreId: {
                    Name: form.store
                }
            }
        } else {
            return null;
        }
    }

    useEffect(() => {
        if (createItemData != null) {
            getStores();
            setOpenSnackBar(true);
            reset();
            setValue('wells', wells); // must reset form wells to wells
            setItemAdded(createItemData);
        }
    }, [createItemResponse])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
    };

    return (
        <Grid container direction="column" px={2}>
            <Grid item>
                <h3>Add Item</h3>
            </Grid>

            <Grid item>
                <form className="createItem" onSubmit={ handleSubmit(handleCreateItem)}>
                    <Stack direction="column" spacing={2}>
                        { wells.length === 0 && 
                            <Alert severity="warning">
                                There are no wells to share this item with. Make sure the selected user is added to one so items may be claimed.
                            </Alert>
                        }
                        { wells.length > 0 &&
                            <FormSelect
                                    name="wells" 
                                    control={control} 
                                    label="Share with..."
                                    variant="outlined"
                                    size="small"
                                    multiple={true}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value.GroupId.Id} label={value.GroupId.Name} />
                                        ))}
                                        </Box>
                                    )
                                    }
                                    selectRender={wells.map((well) => (
                                        <MenuItem
                                        key={well.GroupId.Id}
                                        value={well}
                                        >
                                        {well.GroupId.Name}
                                        </MenuItem>
                                    ))
                                    }
                                    onChange={(event) => {
                                            const {
                                            target: { value },
                                            } = event;
                                            setValue('wells',
                                                typeof value === 'string' ? value.split(',') : value,
                                            );
                                        }
                                    }
                            />
                        }
                        <FormTextField
                                name="name" 
                                control={control} 
                                label="Item Name" 
                                type="text"
                                variant="outlined"
                                error={errors?.name != null}
                                helperText={errors?.name?.message}
                                size="small"
                        />

                        <FormTextField
                                name="description" 
                                control={control} 
                                label="Description" 
                                type="text"
                                variant="outlined"
                                error={errors?.description != null}
                                helperText={errors?.description?.message}
                                size="small"
                                multiline={true}
                        />

                        <FormTextField
                                name="quantity" 
                                control={control} 
                                label="Quantity" 
                                type="number"
                                variant="outlined"
                                error={errors?.quantity != null}
                                helperText={errors?.quantity?.message}
                                size="small"
                                value="1"
                                inputProps={{min: 1}}
                        />
                        <FormAutoComplete
                            name="store"
                            control={control}
                            label="Store"
                            variant="outlined"
                            error={errors?.store != null}
                            helperText={errors?.store?.message}
                            size="small"
                            options={stores}
                            getOptionLabel={(option) => option?.Name || option || ''}
                            freeSolo={true}
                            isOptionEqualToValue={(option, value) => option.Name === value}
                        />
                        <FormTextField
                                name="price" 
                                control={control} 
                                label="Price" 
                                type="text"
                                variant="outlined"
                                error={errors?.price != null}
                                helperText={errors?.price?.message}
                                size="small"
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        />
                        <FormTextField
                                name="url" 
                                control={control} 
                                label="Url" 
                                type="text"
                                variant="outlined"
                                error={errors?.url != null}
                                helperText={errors?.url?.message}
                                size="small"
                        />
                        <LoadingButton loading={createItemLoading} type="submit" variant="contained">Add Item</LoadingButton>
                    </Stack>
                </form>
                {createItemError?.response?.data.errors.map((error) => {
                    return <Alert severity="error">{ error.message }</Alert>;
                })}
                {createItemError?.response?.data.errors == null && createItemError?.response?.data && <Alert severity="error">{JSON.stringify(createItemError?.response?.data)}</Alert>}
                {createItemResponse?.status == 204 && <Alert severity="success">Item created.</Alert> }

                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
                    <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>Item created.</Alert>
                </Snackbar>
            </Grid>
            
        </Grid>
    );
  };
  
export default ItemAdd;