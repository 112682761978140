import * as yup from 'yup';
import { Alert, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, InputAdornment, MenuItem, Snackbar, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormAutoComplete, FormCheckbox, FormSelect, FormTextField } from '../../form/MuiHookForm';
import { useAxiosApi } from '../../axios/useAxiosApi';
import LoadingButton from '@mui/lab/LoadingButton';
import ItemsDashboardContext from './ItemsDashboardContext';
import { Add as AddIcon } from '@mui/icons-material';

const transformStoreObject = function(store, originalStore) {
    const transformedStore = typeof store === 'string' ? originalStore : originalStore?.Name;
    return transformedStore;
}

const validationSchema = yup.object({
    description: yup.string().max(200, 'Must be no more than 200 characters.'),
    quantity: yup.number().min(1, 'Must be one or more.').typeError("Must be an integer."),
    stores: yup.array().of(
        yup.object({
            store: yup.string().transform(transformStoreObject).max(50, 'Must be no more than 50 characters.').required('Store is required.'),
            price: yup.string().matches(/^$|^(0|[1-9][0-9]{0,2})(,\d{3})*(\.\d{1,2})?$/gm, 'Must be a US Dollar amount.'),
            url: yup.string().matches(/^$|(https|http):\/\/.*$/gm, 'Must start with http:// or https://')
        })
    )
});

const ItemEdit = () => { 
    const {selectedItem, setItemEditing, setItemUpdated, stores, wells, setStores} = useContext(ItemsDashboardContext);
    const defaultValues = {
        description: selectedItem.Description,
        quantity: selectedItem.Quantity,
        stores: selectedItem.Stores.map((store) => ({
            itemStoreId: store.Id,
            store: store.StoreId, // StoreId is really a Store object
            price: store.Price != null ? store.Price : '', 
            url: store.Url != null ? store.Url : ''
        })),
        wells: [],
        isReceived: selectedItem.IsReceived
    };

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, setValue, trigger, getValues } = methods;
    const { fields, prepend, remove } = useFieldArray({ name: 'stores', control});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const [{ data: editItemData, loading: editItemLoading, error: editItemError, response: editItemResponse }, executeEditItem] = useAxiosApi(
        {
            method: 'PATCH'
        },
        {
            manual: true
        }
    )

    const [{ data: getStoresData, loading: getStoresLoading, error: getStoresError, response: getStoresResponse }, executeGetStores] = useAxiosApi(
        {
            url: '/items/Stores',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const [{ data: createStoreData, loading: createStoreLoading, error: createStoreError, response: createStoreResponse }, executeCreateStore] = useAxiosApi(
        {
            url: '/items/Stores',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    const handleDeleteItemDialog = () => {
        setOpenDeleteDialog(true);
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteItem = () => {
        setOpenDeleteDialog(false);
        deleteItem();
    }

    const deleteItem = () => {
        executeEditItem({
            url: '/items/Items/' + selectedItem.Id,
            data: {
                IsDeleted: true
            }
        })
    }

    const getStores = () => {
        executeGetStores({
            params: {
                "sort[]": "Name",
            }
        })
    }

    const createStores = (stores) => {
        executeCreateStore({
            data: stores
        })
    }

    useEffect(() => {
        if (createStoreData != null) {
            getStores();
        }
    }, [createStoreResponse])

    useEffect(()=> {
        if(getStoresData != null) {
            setStores(getStoresData.data);
        }
    },[getStoresResponse])

    useEffect(() => {
        if (stores != null && createStoreData != null) {
            handleSubmit(handleEditItem)();
        }
    }, [stores])
    
    const mapStoreName = (storeName) => {
        const matchingStores = stores.filter((store) => store.Name.toUpperCase() === storeName.toUpperCase())
        const matchedStore = matchingStores.length > 0 ? matchingStores[0] : null;
        return matchedStore;
    }

    const mapWell = (wellId) => {
        const matchingWells = selectedItem.Groups.filter((itemGroup) => itemGroup.GroupId.Id === wellId)
        const matchedWell = matchingWells.length > 0 ? matchingWells[0] : null;
        return matchedWell;
    }
    
    const handleEditItem = (form) => {
        const newStores = form.stores.filter((formStore) => {
            const mappedStoreName = mapStoreName(formStore.store);
            return mappedStoreName == null;
        }).map((itemStore) => itemStore.store);

        const uniqueNewStores = newStores.filter((value, index, self) => {
            return self.indexOf(value) === index;
        }).map((uniqueStore) => ({ Name: uniqueStore}));
        if(uniqueNewStores.length > 0) {
            createStores(uniqueNewStores);
        } else {
            editItem(form);
        }
    }

    useEffect(() => {
        if (wells != null) {
            const selectedWellIds = selectedItem.Groups.map((selectedItemGroup) => selectedItemGroup.GroupId.Id);
            const selectedWells = wells.filter((well) => selectedWellIds.includes(well.GroupId.Id));
            setValue('wells', selectedWells);
        }
    }, [wells])

    function editItem(form) {
        const mappedWells = form.wells.map((formWell) => {
            const mappedWell = mapWell(formWell.GroupId.Id)

            if(mappedWell == null) {
                return (
                    {
                        GroupId: formWell.GroupId.Id
                    }
                )
            } else {
                return (
                    {
                        Id: mappedWell.Id
                    }
                )
            }
        });
        const mappedItemStores = form.stores.map((formStore) => {

            const mappedStoreName = mapStoreName(formStore.store);

            const price = formStore.price == '' ? null : formStore.price;
            const url = formStore.url == '' ? null : formStore.url;

            if(formStore.itemStoreId == null) {
                return (
                    {
                        StoreId: mappedStoreName.Id,
                        Price: price,
                        Url: url
                    }
                )
            } else {
                return (
                    {
                        Id: formStore.itemStoreId,
                        StoreId: mappedStoreName.Id,
                        Price: price,
                        Url: url
                    }
                )
            }
        });
        executeEditItem({
            url: '/items/Items/' + selectedItem.Id,
            data: {
                Description: form.description,
                Quantity: form.quantity,
                Stores: mappedItemStores,
                Groups: mappedWells,
                IsReceived: form.isReceived
            }
        })
    }

    useEffect(() => {
        if (editItemData != null) {
            setItemUpdated(editItemData.data);
            setOpenSnackBar(true);
            setItemEditing(false);
        }
    }, [editItemResponse])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
    };

    const addStore = (newStore) => {
        prepend(newStore);
        trigger();
    }

    const removeStore = (index) => {
        remove(index);
        trigger();
    }

    return (
    <Box>
        <form className="editItem" onSubmit={ handleSubmit(handleEditItem)}>
            <Grid container direction="column" spacing={1}>
                <Grid item container spacing={1}>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormTextField
                            name="description" 
                            control={control} 
                            label="Description" 
                            type="text"
                            variant="outlined"
                            error={errors?.description != null}
                            helperText={errors?.description?.message}
                            size="small"
                            multiline={true}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <FormTextField
                            name="quantity" 
                            control={control} 
                            label="Quantity" 
                            type="number"
                            variant="outlined"
                            error={errors?.quantity != null}
                            helperText={errors?.quantity?.message}
                            size="small"
                            value="1"
                            inputProps={{min: 1}}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    {wells.length > 0 &&
                            <FormSelect
                                    name="wells" 
                                    control={control} 
                                    label="Share with..."
                                    variant="outlined"
                                    size="small"
                                    multiple={true}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value.GroupId.Id} label={value.GroupId.Name} />
                                        ))}
                                        </Box>
                                    )
                                    }
                                    selectRender={wells.map((well) => (
                                        <MenuItem
                                        key={well.GroupId.Id}
                                        value={well}
                                        >
                                        {well.GroupId.Name}
                                        </MenuItem>
                                    ))
                                    }
                                    onChange={(event) => {
                                            const {
                                            target: { value },
                                            } = event;
                                            setValue('wells',
                                                typeof value === 'string' ? value.split(',') : value,
                                            );
                                        }
                                    }
                                    fullWidth={true}
                            />
                        }
                    </Grid>
                </Grid>
                <Grid item container direction="column" spacing={1}>
                    <Grid item container alignItems="center" spacing={2}>
                        <Grid item>
                            <h3>Stores</h3>
                        </Grid>
                        <Grid item>
                            <IconButton
                            edge="start"
                            onClick={() => addStore({itemStoreId: null, store: '', price: '', url: ''})}
                            aria-label="edit"
                            color="secondary"
                            >
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid item container direction="row" spacing={1}>
                        {fields && fields.map((store, index) => (
                            <Grid item key={store.id} xs={12} sm={6} md={4} lg={3}>
                                <Card className="store-card">
                                    <CardContent>
                                        <Grid direction="column" container spacing={3}>
                                            <Grid item>
                                                <FormAutoComplete
                                                name={`stores[${index}].store`}
                                                control={control}
                                                label="Store"
                                                variant="outlined"
                                                error={errors['stores[' + index + '].store'] != null}
                                                helperText={errors['stores[' + index + '].store']?.message}
                                                size="small"
                                                options={stores}
                                                getOptionLabel={(option) => option?.Name || option || ''}
                                                freeSolo={true}
                                                isOptionEqualToValue={(option, value) => option.Name === value}
                                                />

                                            </Grid>
                                            <Grid item>
                                                <FormTextField
                                                    name={`stores[${index}].price`}
                                                    control={control} 
                                                    label="Price" 
                                                    type="text"
                                                    variant="outlined"
                                                    error={errors['stores[' + index + '].price'] != null}
                                                    helperText={errors['stores[' + index + '].price']?.message}
                                                    size="small"
                                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField
                                                    name={`stores[${index}].url`}
                                                    control={control} 
                                                    label="Url" 
                                                    type="text"
                                                    variant="outlined"
                                                    error={errors['stores[' + index + '].url'] != null}
                                                    helperText={errors['stores[' + index + '].url']?.message}
                                                    size="small"
                                                    fullWidth={true}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button color="secondary" variant="contained" onClick={() => removeStore(index)} fullWidth>Remove Store</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    
                </Grid>
                <Grid item mt={2}>
                    <Divider />
                </Grid>
                <Grid item mb={3} mt={3}>
                    <FormCheckbox
                        name="isReceived" 
                        control={control} 
                        label="Mark as Received"
                        size="large"
                    />
                </Grid>
                <Grid item container direction="row" justifyContent="space-between">
                    <Grid item container spacing={1} xs={6}>
                        <Grid item>
                            <LoadingButton loading={editItemLoading} type="submit" variant="contained">Save</LoadingButton>
                        </Grid>
                        <Grid item>
                            <Button color="secondary" variant="contained" onClick={() => setItemEditing(false)}>Cancel</Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button color="error" variant="contained" onClick={() => handleDeleteItemDialog()}>Delete Item</Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
        {editItemError?.response?.data.errors.map((error) => {
            return <Alert severity="error">{ error.message }</Alert>;
        })}
        {editItemError?.response?.data.errors == null && editItemError?.response?.data && <Alert severity="error">{JSON.stringify(editItemError?.response?.data)}</Alert>}
        {editItemResponse?.status == 204 && <Alert severity="success">Well updated.</Alert> }

        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
            <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>Well updated.</Alert>
        </Snackbar>

        <Dialog
            open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
        >
        <DialogTitle>
          {"Are you sure you want to delete " + selectedItem.Name + '?'}
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="contained" color="error">No</Button>
          <Button onClick={handleDeleteItem} variant="contained" color="success" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
  
export default ItemEdit;