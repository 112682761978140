import { Add as AddIcon, Person as PersonIcon } from "@mui/icons-material";
import { Avatar, Button, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import { useAxiosApi } from "../../axios/useAxiosApi";
import WellMembersContext from "./WellMembersContext";
import WellsContext from "./WellsContext";

const WellMemberAdd = () => {
    const [searchValue, setSearchValue] = useState('');
    const { wellMembers, wellMembersUpdated, setWellMembersUpdated } = useContext(WellMembersContext);
    const [searchUsers, setSearchUsers] = useState([]);
    const { selectedWell } = useContext(WellsContext);
    
    const [{ data: getUsersData, loading: getUsersLoading, error: getUsersError, response: getUsersResponse }, executeGetUsers] = useAxiosApi(
        {
            url: '/items/Users',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const [{ data: createWellMemberData, loading: createWellMemberLoading, error: createWellMemberError, response: createWellMemberResponse }, executecreateWellMember] = useAxiosApi(
        {
            url: '/items/GroupMembers',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    function createWellMember(user) {
        executecreateWellMember({
            data: {
                MemberId: user.Id,
                GroupId: selectedWell.Id,
                RoleId: 2   // Give member role id for creation
            }
        })
    }
    
    const callGetUsers = function() {
        executeGetUsers({
            params: {
                "fields": "Id,FirstName,LastName,Suffix",
                "search": searchValue
            }
        })
    }

    const handleOnChangeSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const addUserAsMember = (user) => {
        createWellMember(user);
    }

    const filterSearchUsers = (getUsersData) => {
        const filteredUsers = getUsersData.data.filter( ( el ) => { return wellMembers.findIndex(wm => wm.Id === el.Id) === -1});
        setSearchUsers(filteredUsers);
    };

    useEffect(() => {
        if (getUsersData != null) {
            const mappedWellMembers = wellMembers.map((member) => {
                return { Id: member.MemberId.Id, FirstName: member.MemberId.FirstName, LastName: member.MemberId.LastName, Suffix: member.MemberId.Suffix }});
            const filteredUsers = getUsersData.data.filter( ( el ) => { return mappedWellMembers.findIndex(wm => wm.Id === el.Id) === -1});
            setSearchUsers(filteredUsers);
        }
    },[getUsersData])

    useEffect(() => {
        if (wellMembers != null && searchValue != '') {
            callGetUsers();
        }
    }, [wellMembers])

    useEffect(() => {
        if (createWellMemberData != null) {
            setWellMembersUpdated(!wellMembersUpdated);
        }
    }, [createWellMemberData])

    return (
        <Grid container direction="column">
            <Grid container item alignItems="center">
                <Grid item xs={5}>
                    <TextField id="outlined-basic" label="Add User Search" variant="outlined" onChange={handleOnChangeSearch} />
                </Grid>
                <Grid item ml={2}>
                    <Button variant="contained" onClick={callGetUsers} disabled={searchValue == ''}>Search</Button>
                </Grid>
            </Grid>
            { searchUsers && searchUsers.length == 0 &&
                <Grid item>
                    No users found using search parameter.
                </Grid>
            }
            { searchUsers && searchUsers.length > 0 &&
                <Grid item>
                    <List>
                        {searchUsers.map((user) => (
                            <Fragment key={user.Id} >
                                <ListItem 
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="add" onClick={() => addUserAsMember(user)}>
                                            <AddIcon />
                                        </IconButton>
                                    }
                                    className="well-member-list"
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={user.FirstName + ' ' + user.LastName + (user.Suffix ? ' ' + user.Suffix : '')}></ListItemText>
                                </ListItem>
                                <Divider component="li" />
                            </Fragment>
                            
                        ))}
                    </List>
                    
                </Grid>
            }
        </Grid>
    );
};
  
export default WellMemberAdd;