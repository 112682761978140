import * as yup from 'yup';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Container, Stack } from '@mui/material';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {ReactComponent as Logo } from '../../logo.svg'; // Replace with Application Logo

const defaultValues = {
    password: '',
    confirmPassword: '',
    email: '',
    first_name: '',
    last_name: ''
};

const validationSchema = yup.object({
    password: yup.string().required('Please enter a password.'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords do not match!')
    .required('Please confirm password.'),
    email: yup.string().required("Please enter an email.").email("Please enter a valid email."),
    firstName: yup.string().required('Please enter a first name.'),
    lastName: yup.string().required('Please enter a last name.')
});

const Registration = () => {

    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues } = methods;
    
    const [{ data: createUserData, loading: createUserLoading, error: createUserError, response: createUserResponse }, executeCreateUser] = useAxiosApi(
        {
            url: '/users',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    function createUser(form) {
        executeCreateUser({
            data: {
                email: form.email,
                password: form.password,
                first_name: form.firstName,
                last_name: form.lastName
            }
        })
    }
    
    return (
        <Container maxWidth="xs" sx={{marginTop: 5}}>
            <Logo className="logo" />
            <h1>Create Account</h1>
            {createUserResponse?.status != 204 && <form className="createUser" onSubmit={ handleSubmit(createUser)}>
                <Stack spacing={2}>
                    <FormTextField
                            name="firstName" 
                            control={control} 
                            label="First Name" 
                            type="text"
                            variant="outlined"
                            error={errors?.firstName != null}
                            helperText={errors?.firstName?.message}
                    />

                    <FormTextField
                            name="lastName" 
                            control={control} 
                            label="Last Name" 
                            type="text"
                            variant="outlined"
                            error={errors?.lastName != null}
                            helperText={errors?.lastName?.message}
                    />

                    <FormTextField
                            name="email" 
                            control={control} 
                            label="Email" 
                            type="text"
                            variant="outlined"
                            error={errors?.email != null}
                            helperText={errors?.email?.message}
                    />
                    
                    <FormTextField
                            name="password" 
                            control={control} 
                            label="Password" 
                            type="password"
                            variant="outlined"
                            error={errors?.password != null}
                            helperText={errors?.password?.message}
                    />

                    <FormTextField
                            name="confirmPassword" 
                            control={control} 
                            label="Confirm Password" 
                            type="password"
                            variant="outlined"
                            error={errors?.confirmPassword != null}
                            helperText={errors?.confirmPassword?.message}
                    />
                    <LoadingButton loading={createUserLoading} type="submit" variant="contained">Create User</LoadingButton>
                </Stack>
            </form>}
            {createUserError?.response?.data.errors.map((error) => {
                return <Alert severity="error">{ error.message }</Alert>;
            })}
            {createUserError?.response?.data.errors == null && createUserError?.response?.data && <Alert severity="error">{JSON.stringify(createUserError?.response?.data)}</Alert>}
            {createUserResponse?.status == 204 && <Alert severity="success">Account created. Return to <Link component={RouterLink} to={{pathname: "/login" }} underline="none">login</Link>.</Alert> }
        </Container>
    );
}

export default Registration;