import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './routes/Layout';
import Login from './routes/Login/Login';
import Callback from './routes/Login/Callback';
import NoPage from './routes/NoPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deepPurple, teal } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import Registration from './routes/Account/Registration';
import ForgotPassword from './routes/Account/ForgotPassword';
import PasswordReset from './routes/Account/PasswordReset';
import authZConfig from './authZ/authZ.config';
import AccountSettings from './routes/Account/AccountSettings';
import ManageUsers from './routes/Users/ManageUsers';
import { useState } from 'react';
import GlobalStateContext from './GlobalStateContext';
import ItemsDashboard from './routes/Items/ItemsDashboard';
import Wells from './routes/Wells/Wells';
import darkScrollbar from '@mui/material/darkScrollbar';
import SharedItemsDashboard from './routes/Items/SharedItemsDashboard';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: deepPurple,
    secondary: teal,
    logo: {
      main: '#ffffff',
      darker: '#cccccc',
      contrastText: '#000000',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
      }),
    },
  }
});

function App() {

  const [loggedInUser, setLoggedInUser] = useState(localStorage.getItem(authZConfig.localStorageUser));
  const [appUserId, setAppUserId] = useState(parseInt(localStorage.getItem(authZConfig.localStorageAppUserId)));

  return (
    <GlobalStateContext.Provider value={{ loggedInUser, setLoggedInUser, appUserId, setAppUserId }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="callback" element={<Callback />} />
            {authZConfig.defaultLoginEnabled && <Route path="forgotPassword" element={<ForgotPassword />} />}
            {authZConfig.defaultLoginEnabled && <Route path="resetPassword" element={<PasswordReset />} />}
            {authZConfig.defaultLoginEnabled && <Route path="register" element={<Registration />} /> }
            {
              // Protected Routes - Must be logged in
              loggedInUser && 
              <Route path="/" element={<Layout />} >
                <Route path="account/settings" element={<AccountSettings />} />
                <Route path="users/manage" element={<ManageUsers />} />
                <Route path="home" element={<ItemsDashboard />} />
                <Route path="items/shared" element={<SharedItemsDashboard />} />
                <Route path="wells" element={<Wells />} />
                <Route index element={<Navigate to="home" />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            }
            <Route path="*" element={<Navigate to="login" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </GlobalStateContext.Provider>
    
  );
}

export default App;
