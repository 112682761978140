import { CheckCircle as CheckCircleIcon, ShoppingCart as ShoppingCartIcon, Warning as WarningIcon } from "@mui/icons-material";
import { Box, Chip, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Fragment, useContext } from "react";
import AppIconTooltip from "../../common/AppIconTooltip";
import GlobalStateContext from "../../GlobalStateContext";
import SharedItemsDashboardContext from "./SharedItemsDashboardContext";

const Row = (props) => {
  const { item } = props;
  const { setSelectedItem } = useContext(SharedItemsDashboardContext);
  const { appUserId } = useContext(GlobalStateContext);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const prices = item.ItemId.Stores.map((store) => store.Price).filter((price) => price != null);
  let priceRange = '';
  if(prices.length > 0) {
    const maxPrice = Math.max(...prices);
    const minPrice = Math.min(...prices);

    priceRange = minPrice == maxPrice ? currencyFormatter.format(minPrice) : currencyFormatter.format(minPrice) + ' - ' + currencyFormatter.format(maxPrice)
  } else {
    priceRange = '-'
  }
  
  const stores = item.ItemId.Stores.map((itemStore) => ({ Id: itemStore.Id, Name: itemStore.StoreId.Name, Price: itemStore.Price, Url: itemStore.Url }));
  let quantityClaimed = 0;
  item.ItemId.Claimers.map((claimer) => quantityClaimed+=claimer.Quantity);
  
  let quantityClaimedByUser = 0;
  item.ItemId.Claimers.filter((claimer) => claimer.ClaimerId == appUserId).map((claimer) => quantityClaimedByUser+=claimer.Quantity);

  let quantityAvailable = item.ItemId.Quantity - quantityClaimed;

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
        <TableCell component="th" scope="row">
          <Grid container alignItems="center">
                <Grid item sx={{textDecoration: item.ItemId.IsDeleted ? 'line-through': 'none'}}>
                    {item.ItemId.Name}
                </Grid>
                {quantityAvailable === 0 &&
                    <Grid item container alignItems="center">
                        <Grid item sx={{height: 24}}>
                            
                                <AppIconTooltip
                                    title="This item has been completely claimed."
                                    leaveTouchDelay={5000}
                                    mobileLeaveTouchDelay={5000}
                                    placement="top"
                                    mobilePlacement="top"
                                    icon={<CheckCircleIcon color="success" />}
                                />
                        </Grid>
                        <Grid item sx={{fontStyle: 'italic'}}>
                            <Typography component="div" sx={{fontSize: '12px'}}>Already claimed</Typography>
                        </Grid>
                    </Grid>
                } 
                {item.ItemId.IsDeleted && quantityClaimed > 0 &&
                    <Grid item container alignItems="center">
                        <Grid item sx={{height: 24}}>
                            
                                <AppIconTooltip
                                    title="This user no longer wants this item that you claimed."
                                    leaveTouchDelay={5000}
                                    mobileLeaveTouchDelay={5000}
                                    placement="top"
                                    mobilePlacement="top"
                                    icon={<WarningIcon color="warning" />}
                                />
                        </Grid>
                        <Grid item sx={{fontStyle: 'italic'}}>
                            <Typography component="div" sx={{fontSize: '12px'}}>Item has been deleted</Typography>
                        </Grid>
                    </Grid>
                }
              
          </Grid>
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
            {stores.map((store) => (
                <Box key={store.Id}>
                    { store.Url == null &&
                        <Chip label={store.Name} size="small" />
                    }
                    { store.Url != null &&
                        <Chip label={store.Name} size="small" component="a" href={store.Url} target="_blank" sx={{textDecoration: 'underline'}} clickable />
                    }
                </Box>
            ))}
          </Stack>
        </TableCell>
        <TableCell align="center">{priceRange}</TableCell>
        <TableCell align="center">{quantityAvailable}</TableCell>
        <TableCell align="center">{quantityClaimedByUser}</TableCell>
        <TableCell align="center">
            <IconButton 
                aria-label="goToClaimItems"
                onClick={() => setSelectedItem(item)}>
                <ShoppingCartIcon color="secondary" />
            </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const SharedItemsList = () => {
    const { items} = useContext(SharedItemsDashboardContext);
    const { appUserId } = useContext(GlobalStateContext);

    let filteredItems = items.filter((item) => item.ItemId.IsDeleted == false || 
        (item.ItemId.IsDeleted && item.ItemId.Claimers.filter((claimer) => claimer.ClaimerId == appUserId && claimer.Quantity > 0).length > 0 ));

    filteredItems = [...new Map(filteredItems.map((item) => [item.ItemId.Id, item])).values()];

    return (
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table aria-label="collapsible table" size="small" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell sx={{minWidth: 200}}>Item Name</TableCell>
                <TableCell align="left" sx={{width: 300}}>Stores</TableCell>
                <TableCell align="center" sx={{width: 200}}>Price</TableCell>
                <TableCell align="center">Quantity Available</TableCell>
                <TableCell align="center">Quantity Claimed</TableCell>
                <TableCell align="center"></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {filteredItems.map((item) => (
                <Row key={item.ItemId.Id} item={item} />
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}
  
export default SharedItemsList;