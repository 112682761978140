import { Close as CloseIcon, Edit as EditIcon, Info as InfoIcon} from "@mui/icons-material";
import { Box, Card, CardContent, Container, Dialog, Divider, Grid, IconButton, Slide, Tooltip, Typography } from "@mui/material";
import { useState, forwardRef, useContext, useEffect } from "react";
import WellMemberAdd from "../Wells/WellMemberAdd";
import WellMembers from "../Wells/WellMembers";
import ItemEdit from "./ItemEdit";
import ItemReadOnly from "./ItemReadOnly";
import ItemsDashboardContext from "./ItemsDashboardContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ItemDetailsDialog = () => {
  const { selectedItem, setSelectedItem, itemEditing, setItemEditing, itemUpdated, setItemUpdated } = useContext(ItemsDashboardContext);
  const [open, setOpen] = useState(selectedItem != null);
  
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openMobileTooltip, setOpenMobileTooltip] = useState(false);
  const toolTipMessage = "The item name cannot be changed. Changing the name would cause confusion for someone who may have claimed this item. You may delete this item and create another instead.";
  const toolTipLeaveTouchDelay = 5000;

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };
  
  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipMobileClose = () => {
    setOpenMobileTooltip(false);
  };
  
  const handleTooltipMobileOpen = () => {
    setOpenMobileTooltip(true);
  };

  const handleClose = () => {
    setSelectedItem(null);
    setOpen(false);
    setItemEditing(false);
  };

  const handleEdit = () => {
    setItemEditing(true);
  };

  useEffect(() => {
    if (itemUpdated != null && itemUpdated.IsDeleted == true) {
      handleClose();
      setItemUpdated(null);
    }
  }, [itemUpdated])

  return (
    
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box className="dialog-box-bar">
        <IconButton
          edge="start"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Container>
        <Box mt={5}>
          <Grid container justifyContent="start" alignItems="center">
            <Grid container item alignItems="center" alignItems="center">
              <Grid item>
                <Typography variant="h5" sx={{ marginRight: 2}}>{ selectedItem.Name }</Typography>
              </Grid>
              { !itemEditing &&
                <Grid item>
                  
                    <IconButton
                      edge="start"
                      onClick={handleEdit}
                      aria-label="edit"
                      color="secondary"
                    >
                      <EditIcon />
                    </IconButton>
                </Grid>
              } 
              { itemEditing &&
                <Grid item sx={{height: 24}}>
                    <Tooltip
                    open={openTooltip} 
                    onClick={handleTooltipOpen} 
                    onClose={handleTooltipClose}
                    onMouseOver={handleTooltipOpen}
                    onMouseLeave={handleTooltipClose}
                    leaveTouchDelay={toolTipLeaveTouchDelay}
                    title={toolTipMessage} 
                    placement="right" 
                    sx={{display: { xs: 'none', md: 'block' } }}
                    arrow
                >
                    <InfoIcon />
                </Tooltip>
                <Tooltip
                    open={openMobileTooltip} 
                    onClick={handleTooltipMobileOpen} 
                    onClose={handleTooltipMobileClose}
                    onMouseOver={handleTooltipMobileOpen}
                    onMouseLeave={handleTooltipMobileClose}
                    leaveTouchDelay={toolTipLeaveTouchDelay}
                    title={toolTipMessage}
                    placement="bottom" 
                    sx={{display: { xs: 'block', md: 'none' } }}
                    arrow
                >
                    <InfoIcon />
                </Tooltip>
                </Grid>
              }
            </Grid>
          </Grid>
          
          <Card sx={{marginBottom: 3}}>
            
              <CardContent>
                {itemEditing &&
                  <Grid>
                    <Grid item>
                      <ItemEdit />
                    </Grid>
                  </Grid>
                }
                {!itemEditing &&
                  <Grid>
                    <Grid item>
                      <ItemReadOnly />
                    </Grid>
                  </Grid>
                  
                }
              </CardContent>
          </Card>
        </Box>
      </Container>
    </Dialog>
  );
};
  
export default ItemDetailsDialog;