import * as yup from 'yup';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Container, Snackbar, Stack } from '@mui/material';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { useContext, useEffect, useState } from 'react';
import GlobalStateContext from '../../GlobalStateContext';
import { getCurrentUser, setCurrentUser } from '../../authZ/authZ';

const defaultValues = {
    firstName: '',
    lastName: '',
    suffix: ''
};

const validationSchema = yup.object({
    firstName: yup.string().required('Please enter a first name.').max(30, 'Must be no more than 30 characters.'),
    lastName: yup.string().required('Please enter a last name.').max(30, 'Must be no more than 30 characters.'),
    suffix: yup.string().max(10, 'Must be no more than 10 characters.')
});

const AccountSettings = () => {
    const { loggedInUser, setLoggedInUser } = useContext(GlobalStateContext);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues, setValue } = methods;
    
    const [{ data: updateUserData, loading: updateUserLoading, error: updateUserError, response: updateUserResponse }, executeUpdateUser] = useAxiosApi(
        {
            url: '/users/me?fields=first_name,last_name,suffix',
            method: 'PATCH'
        },
        {
            manual: true
        }
    )

    const [{ data: getUserData, loading: getUserLoading, error: getUserError, response: getUserResponse }, executeGetUser] = useAxiosApi(
        {
            url: '/users/me?fields=first_name,last_name,suffix',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    function updateUser(form) {
        executeUpdateUser({
            data: {
                first_name: form.firstName,
                last_name: form.lastName,
                suffix: form.suffix
            }
        })
    }

    useEffect(() => {
        executeGetUser({
        })
    }, [])

    useEffect(() => {
        if (updateUserData != null) {
            setOpenSnackBar(true);
            setCurrentUser(updateUserData);
            setLoggedInUser(getCurrentUser());
        }
    }, [updateUserData])

    useEffect(() => {
        if(getUserData != null) {
            setValue('firstName', getUserData.data.first_name);
            setValue('lastName', getUserData.data.last_name);
            setValue('suffix', getUserData.data.suffix);
        }
    }, [getUserData])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
    };

    return (
        <Container maxWidth="xs" sx={{marginTop: 5}}>
            <h1>Account Settings</h1>
            <form className="updateUser" onSubmit={ handleSubmit(updateUser)}>
                <Stack spacing={2}>
                    <FormTextField
                            name="firstName" 
                            control={control} 
                            label="First Name" 
                            type="text"
                            variant="outlined"
                            error={errors?.firstName != null}
                            helperText={errors?.firstName?.message}
                    />

                    <FormTextField
                            name="lastName" 
                            control={control} 
                            label="Last Name" 
                            type="text"
                            variant="outlined"
                            error={errors?.lastName != null}
                            helperText={errors?.lastName?.message}
                    />

                    <FormTextField
                            name="suffix" 
                            control={control} 
                            label="Suffix" 
                            type="text"
                            variant="outlined"
                            error={errors?.suffix != null}
                            helperText={errors?.suffix?.message}
                    />
                    <LoadingButton loading={updateUserLoading} type="submit" variant="contained">Save</LoadingButton>
                </Stack>
            </form>
            {updateUserError?.response?.data.errors.map((error) => {
                return <Alert severity="error">{ error.message }</Alert>;
            })}
            {updateUserError?.response?.data.errors == null && updateUserError?.response?.data && <Alert severity="error">{JSON.stringify(updateUserError?.response?.data)}</Alert>}
            {updateUserResponse?.status == 204 && <Alert severity="success">Account information updated.</Alert> }

            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>Account Settings Updated</Alert>
            </Snackbar>
        </Container>
    );
};

export default AccountSettings;