import * as yup from 'yup';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Card, CardContent, Container, Divider, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { useContext, useEffect, useState } from 'react';
import GlobalStateContext from '../../GlobalStateContext';
import authZConfig from '../../authZ/authZ.config';

const defaultValues = {
    firstName: '',
    lastName: '',
    suffix: ''
};

const validationSchema = yup.object({
    firstName: yup.string().required('Please enter a first name.').max(30, 'Must be no more than 30 characters.'),
    lastName: yup.string().required('Please enter a last name.').max(30, 'Must be no more than 30 characters.'),
    suffix: yup.string().max(10, 'Must be no more than 10 characters.')
});

const ManageUsers = () => {
    const { appUserId } = useContext(GlobalStateContext);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues, setValue } = methods;
    const [users, setUsers] = useState([]);

    const [{ data: createUserData, loading: createUserLoading, error: createUserError, response: createUserResponse }, executecreateUser] = useAxiosApi(
        {
            url: '/items/Users?fields=FirstName,LastName,Suffix,IsManagedUser',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    const [{ data: getUsersData, loading: getUsersLoading, error: getUsersError, response: getUsersResponse }, executeGetUsers] = useAxiosApi(
        {
            url: '/items/UserManagers',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const callGetUsers = function() {
        executeGetUsers({
            params: {
                "filter[UserId][IsManagedUser][_eq]": true,
                "filter[ManagerId][_eq]": appUserId,
                "filter[RoleId][_eq]": 1,   // Get only Users one is Owner of
                "fields": "UserId.Id,UserId.FirstName,UserId.LastName,UserId.Suffix"
            }
        })
    }

    function createUser(form) {
        executecreateUser({
            data: {
                FirstName: form.firstName,
                LastName: form.lastName,
                Suffix: form.suffix,
                IsManagedUser: true
            }
        })
    }

    useEffect(() => {
        callGetUsers();
    }, [])

    useEffect(() => {
        if (createUserData != null) {
            setOpenSnackBar(true);
            callGetUsers();
        }
    }, [createUserData])

    useEffect(() => {
        if(getUsersData != null) {
            const mappedUsers = getUsersData.data.map((user) => {
                return {Id: user.UserId.Id, FirstName: user.UserId.FirstName, LastName: user.UserId.LastName, Suffix: user.UserId.Suffix }
            });
            setUsers(mappedUsers);
        }
    }, [getUsersData])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
    };

    return (
        <Container maxWidth="md" sx={{marginTop: 5}}>
            <h1>User Management</h1>
            
            <Grid container rowSpacing={2} columnSpacing={2}>
                { users.map((user) => (
                    <Grid key={user.Id} item xs={6} sm={4}>
                        <Card className="managed-user-card" sx={{display: "flex", justifyContent:"center", alignContent: "center"}}>
                            <CardContent className="managed-user-card-content">
                                <Typography align="center" className="managed-user-card-text" color="text.secondary" gutterBottom>
                                    { user.FirstName }
                                </Typography>
                                <Typography align="center" className="managed-user-card-text" color="text.secondary" gutterBottom>
                                    { user.LastName } { user.Suffix ? user.Suffix : ''}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            { users.length > 0 && <Divider sx={{marginTop: 5, marginBottom: 5}} /> }
            <form className="createUser" onSubmit={ handleSubmit(createUser)}>
                <Stack direction={{md: "row", xs: "column"}} spacing={2}>
                    <FormTextField
                            name="firstName" 
                            control={control} 
                            label="First Name" 
                            type="text"
                            variant="outlined"
                            error={errors?.firstName != null}
                            helperText={errors?.firstName?.message}
                            size="small"
                    />

                    <FormTextField
                            name="lastName" 
                            control={control} 
                            label="Last Name" 
                            type="text"
                            variant="outlined"
                            error={errors?.lastName != null}
                            helperText={errors?.lastName?.message}
                            size="small"
                    />

                    <FormTextField
                            name="suffix" 
                            control={control} 
                            label="Suffix" 
                            type="text"
                            variant="outlined"
                            error={errors?.suffix != null}
                            helperText={errors?.suffix?.message}
                            size="small"
                    />
                    <LoadingButton loading={createUserLoading} type="submit" variant="contained">Add User</LoadingButton>
                </Stack>
            </form>
            {createUserError?.response?.data.errors.map((error) => {
                return <Alert severity="error">{ error.message }</Alert>;
            })}
            {createUserError?.response?.data.errors == null && createUserError?.response?.data && <Alert severity="error">{JSON.stringify(createUserError?.response?.data)}</Alert>}
            {createUserResponse?.status == 204 && <Alert severity="success">User created.</Alert> }

            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
                <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>User created.</Alert>
            </Snackbar>
        </Container>
    );
  };
  
export default ManageUsers;