import { Box, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useAxiosApi } from "../../axios/useAxiosApi";
import GlobalStateContext from "../../GlobalStateContext";
import SharedItemsClaimDialog from "./SharedItemsClaimDialog";
import SharedItemsDashboardContext from "./SharedItemsDashboardContext";
import SharedItemsList from "./SharedItemsList";
import SharedItemsUserSelector from "./SharedItemsUserSelector";


const SharedItemsDashboard = () => {
    const [selectedUserId, setSelectedUserId] = useState(null);
    const { appUserId } = useContext(GlobalStateContext);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemClaimUpdated, setItemClaimUpdated] = useState(null);

    const [{ data: getItemsData, loading: getItemsLoading, error: getItemsError, response: getItemsResponse }, executeGetItems] = useAxiosApi(
        {
            url: '/items/Users',
            method: 'GET'
        },
        {
            manual: true
        }
    )
    
    const getItems = () => {
        executeGetItems({
            params: {
                "filter[Id][_eq]": selectedUserId,
                "deep[Items][_filter][ItemId][Groups][GroupId][Members][MemberId][_in]": appUserId,
                "fields": "Id,Items.ItemId.*,Items.ItemId.Stores.*,Items.ItemId.Stores.StoreId.*,Items.ItemId.Groups.GroupId,Items.ItemId.Claimers.*",
                "deep[Items][_sort]": "ItemId.Name"
            }
        })
    }

    useEffect(() => {
        if(selectedUserId != null) {
            getItems();
        }
    }, [selectedUserId])

    useEffect(() =>  {
        if (getItemsData != null && getItemsData.data.length == 1) {
            setItems(getItemsData.data[0].Items)
        }
    },[getItemsResponse])

    useEffect(() => {
        if(itemClaimUpdated != null) {
            getItems();
        }
    }, [itemClaimUpdated])
    
    return (
        <SharedItemsDashboardContext.Provider value={{selectedUserId, setSelectedUserId, selectedItem, setSelectedItem, items, setItemClaimUpdated}}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center" mt={1} spacing={5} px={2}>
                <Grid item>
                    <SharedItemsUserSelector />
                </Grid>
                <Grid item sx={{maxWidth: '100% !important'}}>
                    {items.length > 0 && <SharedItemsList /> }
                    {items.length === 0 && <Typography component="div" sx={{fontStyle: 'italic'}}>This user has not shared any items.</Typography> }
                </Grid>
            </Grid>
            {selectedItem && <SharedItemsClaimDialog />}
        </SharedItemsDashboardContext.Provider>
    )
}

export default SharedItemsDashboard;