import { Tooltip } from "@mui/material";
import { useState } from "react";

const AppIconTooltip = ({title, leaveTouchDelay, mobileLeaveTouchDelay, placement, mobilePlacement, icon}) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const [openMobileTooltip, setOpenMobileTooltip] = useState(false);

    const handleTooltipClose = () => {
        setOpenTooltip(false);
    };
      
    const handleTooltipOpen = () => {
        setOpenTooltip(true);
    };

    const handleTooltipMobileClose = () => {
        setOpenMobileTooltip(false);
    };
    
    const handleTooltipMobileOpen = () => {
        setOpenMobileTooltip(true);
    };

    return (
        <>
            <Tooltip
                open={openTooltip} 
                onClick={handleTooltipOpen} 
                onClose={handleTooltipClose}
                onMouseOver={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}
                leaveTouchDelay={leaveTouchDelay}
                title={title} 
                placement={placement} 
                sx={{display: { xs: 'none', md: 'block' } }}
                arrow
            >
                {icon}
            </Tooltip>
            <Tooltip
                open={openMobileTooltip} 
                onClick={handleTooltipMobileOpen} 
                onClose={handleTooltipMobileClose}
                onMouseOver={handleTooltipMobileOpen}
                onMouseLeave={handleTooltipMobileClose}
                leaveTouchDelay={mobileLeaveTouchDelay}
                title={title}
                placement={mobilePlacement} 
                sx={{display: { xs: 'block', md: 'none' } }}
                arrow
            >
                {icon}
            </Tooltip>
        </>
        
    )
}

export default AppIconTooltip