import * as yup from 'yup';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Card, CardContent, Container, Divider, Grid, Snackbar, Stack, Typography } from '@mui/material';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { useContext, useEffect, useState } from 'react';
import GlobalStateContext from '../../GlobalStateContext';
import WellDetailsDialog from './WellDetailsDialog';
import WellsContext from './WellsContext';

const defaultValues = {
    name: '',
    description: ''
};

const validationSchema = yup.object({
    name: yup.string().required('Please enter a name.').max(30, 'Must be no more than 30 characters.'),
    description: yup.string().max(200, 'Must be no more than 200 characters.')
});

const Wells = () => {
    const { appUserId } = useContext(GlobalStateContext);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors } } = methods;
    const [wells, setWells] = useState([]);
    const [selectedWell, setSelectedWell] = useState(null);
    const [wellEditing, setWellEditing] = useState(false);

    const [{ data: createWellData, loading: createWellLoading, error: createWellError, response: createWellResponse }, executecreateWell] = useAxiosApi(
        {
            url: '/items/GroupMembers',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    const [{ data: getWellsData, loading: getWellsLoading, error: getWellsError, response: getWellsResponse }, executeGetWells] = useAxiosApi(
        {
            url: '/items/GroupMembers',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const callGetWells = function() {
        executeGetWells({
            params: {
                "filter[MemberId][_eq]": appUserId,
                "fields": "GroupId.Id,GroupId.Name,GroupId.Description,RoleId"
            }
        })
    }

    function createWell(form) {
        executecreateWell({
            data: {
                GroupId: {
                    Name: form.name,
                    Description: form.description
                },
                MemberId: appUserId,
                RoleId: 1   // Give owner role id for well creation
            }
        })
    }

    useEffect(() => {
        callGetWells();
    }, [])

    useEffect(() => {
        if (createWellData != null) {
            setOpenSnackBar(true);
            callGetWells();
        }
    }, [createWellData])

    useEffect(() => {
        if(getWellsData != null) {
            const mappedWells = getWellsData.data.map((well) => {
                return {Id: well.GroupId.Id, Name: well.GroupId.Name, Description: well.GroupId.Description, IsOwner: well.RoleId == 1 ? true : false }
            });
            setWells(mappedWells);
        }
    }, [getWellsData])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
    };

    return (
        <WellsContext.Provider value={{ selectedWell, setSelectedWell, setWells, wellEditing, setWellEditing }}>
            <Container maxWidth="md">
                <Grid container direction="column">
                    <Grid item>
                        <h1 className="well-page-title">Wells (Groups)</h1>
                    </Grid>
                    <Grid item>
                        <h4 className="well-page-subtitle">A group of people that share items with each other</h4>
                    </Grid>
                </Grid>
                
                <Grid container rowSpacing={2} columnSpacing={2}>
                    { wells.map((well) => (
                        <Grid key={well.Id} item xs={6} sm={4}>
                            <Card className="well-card" sx={{display: "flex", justifyContent:"center", alignContent: "center"}} onClick={() => setSelectedWell(well)}>
                                <CardContent className="well-card-content">
                                    <Typography align="center" className="well-card-text" color="text.secondary" gutterBottom>
                                        { well.Name }
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
                { selectedWell && <WellDetailsDialog /> }
                { wells.length > 0 && <Divider sx={{marginTop: 5, marginBottom: 5}} /> }
                <form className="createWell" onSubmit={ handleSubmit(createWell)}>
                    <Stack direction={{md: "row", xs: "column"}} spacing={2}>
                        <FormTextField
                                name="name" 
                                control={control} 
                                label="Name" 
                                type="text"
                                variant="outlined"
                                error={errors?.name != null}
                                helperText={errors?.name?.message}
                                size="small"
                        />

                        <FormTextField
                                name="description" 
                                control={control} 
                                label="Description" 
                                type="text"
                                variant="outlined"
                                error={errors?.description != null}
                                helperText={errors?.description?.message}
                                size="small"
                        />
                        <LoadingButton loading={createWellLoading} type="submit" variant="contained">Add Well</LoadingButton>
                    </Stack>
                </form>
                {createWellError?.response?.data.errors.map((error) => {
                    return <Alert severity="error">{ error.message }</Alert>;
                })}
                {createWellError?.response?.data.errors == null && createWellError?.response?.data && <Alert severity="error">{JSON.stringify(createWellError?.response?.data)}</Alert>}
                {createWellResponse?.status == 204 && <Alert severity="success">Well created.</Alert> }

                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
                    <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>Well created.</Alert>
                </Snackbar>
            </Container>
        </WellsContext.Provider>
    );
  };
  
export default Wells;