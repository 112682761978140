import { Container, Grid, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import gif404 from '../404.gif'; // Replace with Application Logo

const NoPage = () => {
    return (
      <Container>
        <Grid container justifyContent="center">
          <Grid item>
            <img className="gif404" src={gif404} alt="404 Gif" />
          </Grid>
          <Grid item>
            <Typography mt={5} align="center" variant="h5" gutterBottom>
            We're sad too! The page you were looking for could not be found. Click <RouterLink to={{pathname: "/home" }} className="routerlink">here</RouterLink> to return to home.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  };
  
export default NoPage;