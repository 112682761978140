const authZConfig = {
    defaultLoginEnabled: true,
    selfRegistrationEnabled: true,
    providerLoginEnabled: true,
    facebookLoginEnabled: true,
    googleLoginEnabled: true,
    microsoftLoginEnabled: true,
    yahooLoginEnabled: true,
    gatewayBaseUrl: "WW_GATEWAY_BASE_URL",
    loginSuccessRoute: '/home',
    localStorageUser: 'loggedInUser',
    localStorageAppUserId: 'appUserId'
};

export default authZConfig;