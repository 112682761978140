import authZConfig from './authZ.config';

const isAuthenticated = () => {
    return localStorage.getItem(authZConfig.localStorageUser) != null;
}

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem(authZConfig.localStorageUser));
}

const setCurrentUser = (currentUser) => {
    localStorage.setItem(authZConfig.localStorageUser, JSON.stringify(currentUser?.data));
}

export { isAuthenticated, getCurrentUser, setCurrentUser };