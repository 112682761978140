import { Http } from '@mui/icons-material';
import axios from 'axios';
import { makeUseAxios } from 'axios-hooks'
import authZConfig from '../authZ/authZ.config';

const api = axios.create({
    baseURL: authZConfig.gatewayBaseUrl + '/api',
    withCredentials: true
});

const gateway = axios.create({
    baseURL: authZConfig.gatewayBaseUrl,
    withCredentials: true
});

api.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});

const refreshLogin = async () => {
       
    let refreshResponse;

    await gateway.post('/login/refresh', {
    })
    .then(function (response) {
        refreshResponse = response;
    })
    .catch(function (error) {
        refreshResponse = error.response;
    });

    return refreshResponse;
};

const logout = async () => {
       
    let logoutResponse;

    await gateway.post('/logout', {
    })
    .then(function (response) {
        logoutResponse = response;
        localStorage.removeItem(authZConfig.localStorageUser);
        localStorage.removeItem(authZConfig.localStorageAppUserId);
        window.location.href = '/login'
    })
    .catch(function (error) {
        logoutResponse = error.response;
    });

    return logoutResponse;
};

let refreshTokenPromise; // holds any in progress refresh token requests

api.interceptors.response.use(response => {
    return response;
}, async error => {
    const config = error.config;
    if (error.response?.status === 401 && !config._retry) {
        
        if(!refreshTokenPromise) {  // if no existing refresh token request, start a new one
            refreshTokenPromise = refreshLogin().then(async (refreshResponse) => {
                refreshTokenPromise = null; // token was refreshed so no active token refresh anymore
                if (refreshResponse.status == 200) {
                    return;
                } else {
                    const logoutResponse = await logout();
                    return Promise.reject(logoutResponse);
                }
            })
        }

        return refreshTokenPromise.then(() => {
            return api(config);
        })
    }
    return Promise.reject(error);
});

const useAxiosApi = makeUseAxios({
    axios: api,
    cache: false
})

export { useAxiosApi, logout }
