import { Add as AddIcon, Close as CloseIcon, Edit as EditIcon, Info as InfoIcon, Remove as RemoveIcon} from "@mui/icons-material";
import { Alert, Box, Button, Card, CardContent, Container, Dialog, Divider, Grid, IconButton, Link, Slide, Snackbar, Tooltip, Typography } from "@mui/material";
import { useState, forwardRef, useContext, useEffect } from "react";
import { useAxiosApi } from "../../axios/useAxiosApi";
import GlobalStateContext from "../../GlobalStateContext";
import SharedItemsDashboardContext from "./SharedItemsDashboardContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SharedItemsClaimDialog = () => {
    const { selectedItem, setSelectedItem, itemClaimUpdated, setItemClaimUpdated } = useContext(SharedItemsDashboardContext);
    const { appUserId } = useContext(GlobalStateContext);
    const [open, setOpen] = useState(selectedItem != null);
    const [quantityAvailable, setQuantityAvailable] = useState(null);
    const [quantityClaimed, setQuantityClaimed] = useState(null);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [openSuccessSnackBar, setOpenSuccessSnackBar] = useState(false);

    const [{ data: getItemClaimsData, loading: getItemClaimsLoading, error: getItemClaimsError, response: getItemClaimsResponse }, executeGetItemClaims] = useAxiosApi(
        {
            url: '/items/ItemClaimers',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const [{ data: editItemClaimerData, loading: editItemClaimerLoading, error: editItemClaimerError, response: editItemClaimerResponse }, executeEditItemClaimer] = useAxiosApi(
        {
            method: 'PATCH'
        },
        {
            manual: true
        }
    )

    const [{ data: createItemClaimerData, loading: createItemClaimerLoading, error: createItemClaimerError, response: createItemClaimerResponse }, executeCreateItemClaimer] = useAxiosApi(
        {
            url: '/items/ItemClaimers',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    const getItemClaims = () => {
        executeGetItemClaims({
            params: {
                "filter[ItemId][_eq]": selectedItem.ItemId.Id,
            }
        })
    }

    const editItemClaimer = (itemClaimerId, quantityClaimed) => {
        executeEditItemClaimer({
            url: '/items/ItemClaimers/' + itemClaimerId,
            data: {
                Quantity: quantityClaimed
            }
        })
    }

    function createItemClaimer(quantityClaimed) {
        executeCreateItemClaimer({
            data: {
                ClaimerId: appUserId,
                ItemId: selectedItem.ItemId.Id,
                Quantity: quantityClaimed
            }
        })
    }

    const handleSaveClaim = () => {
        getItemClaims();
    }

    useEffect(() => {
        if(getItemClaimsData != null) {
            let totalClaimed = 0;
            getItemClaimsData.data.filter((claimer) => claimer.ClaimerId != appUserId).map((claimer) => totalClaimed+=claimer.Quantity);
            const totalAvailable = selectedItem.ItemId.Quantity - totalClaimed;
            if (quantityClaimed <= totalAvailable) {
                // Save Claim
                const userItemClaimers = selectedItem.ItemId.Claimers.filter((claimer) => claimer.ClaimerId == appUserId);
                if (userItemClaimers.length > 0) {
                    const itemClaimerId = userItemClaimers[0].Id;
                    editItemClaimer(itemClaimerId, quantityClaimed);
                } else {
                    createItemClaimer(quantityClaimed);
                }
            } else {
                // Can't claim because somebody else claimed while viewing page
                let quantityClaimedByUser = 0;
                getItemClaimsData.data.filter((claimer) => claimer.ClaimerId == appUserId).map((claimer) => quantityClaimedByUser+=claimer.Quantity);

                setQuantityAvailable(totalAvailable);
                setQuantityClaimed(quantityClaimedByUser);
                setOpenSnackBar(true);
            }
        }
    },[getItemClaimsResponse])

    useEffect(() => {
        if (editItemClaimerData != null) {
            setItemClaimUpdated(editItemClaimerData.data);
            setOpenSuccessSnackBar(true);
        }
    },[editItemClaimerResponse])

    useEffect(() => {
        if (createItemClaimerData != null) {
            setItemClaimUpdated(createItemClaimerData.data);
            setOpenSuccessSnackBar(true);
        }
    },[createItemClaimerResponse])

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleClose = () => {
        setSelectedItem(null);
        setOpen(false);
    };

    useEffect(() => {
        if (itemClaimUpdated != null) {
        handleClose();
        setItemClaimUpdated(null);
        }
    }, [itemClaimUpdated])

    useEffect(() => {
        if(selectedItem != null) {
            let quantityClaimed = 0;
            selectedItem.ItemId.Claimers.map((claimer) => quantityClaimed+=claimer.Quantity);
            
            let quantityClaimedByUser = 0;
            selectedItem.ItemId.Claimers.filter((claimer) => claimer.ClaimerId == appUserId).map((claimer) => quantityClaimedByUser+=claimer.Quantity);
        
            let quantityAvailable = selectedItem.ItemId.Quantity - quantityClaimed;
            setQuantityAvailable(quantityAvailable);
            setQuantityClaimed(quantityClaimedByUser);
        }
    },[selectedItem])

    const decrementClaimed = () => {
        if(quantityClaimed > 0) {
            setQuantityAvailable(quantityAvailable + 1);
            setQuantityClaimed(quantityClaimed - 1);
        }
    }

    const incrementClaimed = () => {
        if(quantityAvailable > 0) {
            setQuantityAvailable(quantityAvailable - 1);
            setQuantityClaimed(quantityClaimed + 1);
        }
    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
        setOpenSuccessSnackBar(false);
    };

    return (
        
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        >
        <Box className="dialog-box-bar">
            <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
            >
            <CloseIcon />
            </IconButton>
        </Box>
        <Divider />
        <Container>
            <Box mt={5}>
            <Grid container justifyContent="start" alignItems="center">
                <Grid container item alignItems="center" alignItems="center">
                <Grid item>
                    <Typography variant="h5" sx={{ marginRight: 2}}>{ selectedItem.ItemId.Name }</Typography>
                </Grid>
                </Grid>
            </Grid>
            
            <Card sx={{marginBottom: 3}}>
                
                <CardContent>
                    <Grid container>
                        {selectedItem.ItemId.Description &&
                            <Grid item>
                                {selectedItem.ItemId.Description}
                            </Grid>
                        }
                        <Grid container>
                            <Grid item container direction="column">
                                <Grid item>
                                    <h2>{quantityAvailable} Available</h2>
                                </Grid>
                                <Grid item container direction="column">
                                    <Grid item>
                                        <h2 sx={{ m: 0 }}>Claiming</h2>
                                    </Grid>
                                    <Grid item container alignItems="center">
                                        <Grid item container alignItems="center" gap={1}>
                                            <Grid item>
                                                <IconButton size="large" onClick={decrementClaimed} disabled={quantityClaimed == 0}>
                                                    <RemoveIcon color="secondary" />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <h2>{quantityClaimed}</h2>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="large" onClick={incrementClaimed} disabled={quantityAvailable == 0}>
                                                    <AddIcon color="secondary" />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" onClick={handleSaveClaim}>Save</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
                                    <Alert onClose={handleSnackBarClose} severity="warning" sx={{ width: '100%' }}>Could not claim item. Someone else claimed this item before you.</Alert>
                                </Snackbar>

                                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSuccessSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
                                    <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>Save Successful!</Alert>
                                </Snackbar>
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Stores</h3>
                            </Grid>
                            {selectedItem.ItemId.Stores.map((itemStore) => (
                                <Grid key={itemStore.Id} item container direction="row" gap={2}>
                                    {itemStore.Url &&
                                        <Grid item>
                                            <Link href={itemStore.Url} target="_blank" color="inherit">{itemStore.StoreId.Name}</Link>
                                        </Grid>
                                    }
                                    {!itemStore.Url &&
                                        <Grid item>
                                            {itemStore.StoreId.Name}
                                        </Grid>
                                    }
                                    <Grid item>{itemStore.Price != null ? currencyFormatter.format(itemStore.Price) : ''}</Grid>
                                </Grid>
                            ))}
                            {selectedItem.ItemId.Stores.length == 0 &&
                                <Grid item sx={{fontStyle: 'italic'}}>
                                    <Typography variant="p" sx={{fontStyle: 'italic'}}>No stores have been added.</Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            </Box>
        </Container>
        </Dialog>
    );
};
  
export default SharedItemsClaimDialog;