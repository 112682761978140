import { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useAxiosApi } from '../../axios/useAxiosApi';
import ItemsDashboardContext from './ItemsDashboardContext';
import authZConfig from '../../authZ/authZ.config';
import GlobalStateContext from '../../GlobalStateContext';

const ItemUserSelector = () => {
    const [user, setUser] = useState('');
    const [users, setUsers] = useState([]);
    const { itemUserId, setItemUserId, setWells } = useContext(ItemsDashboardContext);
    const { appUserId } = useContext(GlobalStateContext);

    const handleChange = (event) => {
        setUser(event.target.value);
        setItemUserId(event.target.value);
    };

    const [{ data: getUsersData, loading: getUsersLoading, error: getUsersError, response: getUsersResponse }, executeGetUsers] = useAxiosApi(
        {
            url: '/items/UserManagers',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    useEffect(() => {
        executeGetUsers({
            params: {
                "filter[UserId][IsManagedUser][_eq]": true,
                "filter[ManagerId][_eq]": appUserId,
                "fields": "UserId.Id,UserId.FirstName,UserId.LastName,UserId.Suffix"
            }
        })
    }, [])

    useEffect(() => {
        if(getUsersData != null) {
            let mappedUsers = getUsersData.data.map((user) => {
                return {Id: user.UserId.Id, FirstName: user.UserId.FirstName, LastName: user.UserId.LastName, Suffix: user.UserId.Suffix }
            });

            // Add Logged In User to mappedUser
            let loggedInUser = JSON.parse(localStorage.getItem(authZConfig.localStorageUser));
            loggedInUser = {Id: appUserId, FirstName: loggedInUser.first_name, LastName: loggedInUser.last_name, Suffix: loggedInUser.suffix };
            mappedUsers = [loggedInUser, ...mappedUsers];

            setUsers(mappedUsers);
        }
    }, [getUsersData])

    useEffect(() => {
        if(users != null && users.length > 0) {
            const firstUser = users[0];
            setUser(firstUser.Id);
            setItemUserId(firstUser.Id);
        }
    }, [users])

    return (
    
        <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
                <InputLabel id="item-user-select-label">User</InputLabel>
                <Select
                    labelId="item-user-select-label"
                    id="item-user-select"
                    value={user}
                    label="User"
                    onChange={handleChange}
                >
                    { users && users.map((user) => { return <MenuItem key={user.Id} value={user.Id}>{user.FirstName} {user.LastName}{ user.Suffix ? ' ' + user.Suffix : ''}</MenuItem> } )}
                </Select>
            </FormControl>
        </Box>

    );
  };
  
export default ItemUserSelector;