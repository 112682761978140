import { Add as AddIcon, Remove as RemoveIcon} from "@mui/icons-material";
import { Fab, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAxiosApi } from "../../axios/useAxiosApi";
import ItemAdd from "./ItemAdd";
import ItemDetailsDialog from "./ItemDetailsDialog";
import ItemsDashboardContext from "./ItemsDashboardContext";
import ItemsList from "./ItemsList";
import ItemUserSelector from "./ItemUserSelector";

const ItemsDashboard = () => {
    const [itemUserId, setItemUserId] = useState(null);
    const [openAddItem, setOpenAddItem] = useState(false);
    const [stores, setStores] = useState([]);
    const [wells, setWells] = useState([]);
    const [items, setItems] = useState([]);
    const [itemAdded, setItemAdded] = useState(null);
    const [itemUpdated, setItemUpdated] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [itemEditing, setItemEditing] = useState(false);

    const handleAddItemClick = () => {
        setOpenAddItem(!openAddItem);
    };

    const [{ data: getWellsData, loading: getWellsLoading, error: getWellsError, response: getWellsResponse }, executeGetWells] = useAxiosApi(
        {
            url: '/items/GroupMembers',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const [{ data: getItemsData, loading: getItemsLoading, error: getItemsError, response: getItemsResponse }, executeGetItems] = useAxiosApi(
        {
            url: '/items/Items',
            method: 'GET'
        },
        {
            manual: true
        }
    )
    
    const getItems = () => {
        executeGetItems({
            params: {
                "filter[Users][UserId][Id][_eq]": itemUserId,
                "filter[IsDeleted][_eq]": false,
                "filter[IsReceived][_eq]": false,
                "fields": "*.*.*",
                "sort[]": "Name"
            }
        })
    }

    useEffect(() => {
        if(itemUserId != null) {
            executeGetWells({
                params: {
                    "filter[MemberId][_eq]": itemUserId,
                    "fields": "GroupId.Id,GroupId.Name",
                    "sort[]": "GroupId.Name"
                }
            })

            getItems();
        }
    }, [itemUserId])

    useEffect(() => {
        if(itemAdded != null) {
            getItems();
        }
    }, [itemAdded])

    useEffect(() => {
        if(itemUpdated != null) {
            getItems();
        }
    }, [itemUpdated])

    useEffect(() => {
        if (getWellsData != null) {
            setWells(getWellsData.data);
        }
    }, [getWellsResponse])

    useEffect(() => {
        if (getItemsData != null) {
            setItems(getItemsData.data);
            if(selectedItem != null) {
                const updatedSelectedItem = getItemsData.data.filter((item) => item.Id == selectedItem.Id)[0];
                setSelectedItem(updatedSelectedItem);
            }
        }
    }, [getItemsResponse])

    return (
        <ItemsDashboardContext.Provider value={{ itemUserId, setItemUserId, stores, setStores, wells, setWells, items, setItemAdded, selectedItem, setSelectedItem, itemEditing, setItemEditing, setItemUpdated, itemUpdated }}>
            <Grid mt={0} container spacing={2} justifyContent="space-around" alignItems="stretch">
                <Grid item lg={3} md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <ItemAdd />
                </Grid>
                <Grid mt={0} direction="column" container item md={8} rowSpacing={2}>
                    <Grid container justifyContent="flex-start" alignItems="center" columnSpacing={2} px={2}>
                        <Grid item xs={5} sm={4} lg={3}>
                            <ItemUserSelector />
                        </Grid>
                        <Grid item xs={5} sm={4} lg={3}></Grid>
                        <Grid item xs={2} sm={4} lg={3} sx={{ display: { xs: 'block', md: 'none' }, textAlign: "right" }}>
                            <Fab size="small" color="secondary" onClick={handleAddItemClick}>
                                {!openAddItem && <AddIcon />}
                                {openAddItem && <RemoveIcon />}
                            </Fab>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={4} sx={{ display: { xs: 'inline-flex', md: 'none' } }}>
                        {openAddItem && <ItemAdd /> }
                    </Grid>
                    <Grid item xs={4} px={2} sx={{maxWidth: '100% !important'}}>
                        { items.length > 0 && <ItemsList /> }
                        { items.length == 0 && <Typography component="div" sx={{fontStyle: 'italic'}}>Start sharing by adding items for this user!</Typography> }
                    </Grid>
                </Grid>
            </Grid>
            {selectedItem && <ItemDetailsDialog />}
        </ItemsDashboardContext.Provider>
    );
  };
  
export default ItemsDashboard;