import { Delete as DeleteIcon, Person as PersonIcon } from "@mui/icons-material";
import { Avatar, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Fragment, useContext, useEffect } from "react";
import { useAxiosApi } from "../../axios/useAxiosApi";
import WellMembersContext from "./WellMembersContext";
import WellsContext from "./WellsContext";

const WellMembers = () => {
    const { selectedWell } = useContext(WellsContext);
    const { wellMembers, setWellMembers, wellMembersUpdated, setWellMembersUpdated} = useContext(WellMembersContext);

    const [{ data: deleteWellMemberData, loading: deleteWellMemberLoading, error: deleteWellMemberError, response: deleteWellMemberResponse }, executeDeleteWellMember] = useAxiosApi(
        {
            method: 'DELETE'
        },
        {
            manual: true
        }
    )

    function deleteWellMember(wellMember) {
        executeDeleteWellMember({
            url: '/items/GroupMembers/' + wellMember.Id,
        })
    }
    
    const removeUserAsMember = (user) => {
        deleteWellMember(user);
    };

    useEffect(() => {
        if(deleteWellMemberResponse != null) {
            setWellMembersUpdated(!wellMembersUpdated)
        }
    },[deleteWellMemberResponse])
    return (
        <Grid direction="column" container>
            <Grid item>
                <h3>Members</h3>
            </Grid>
            <Grid item>
                <List>
                    {wellMembers && wellMembers.map((user) => (
                        <Fragment key={user.Id}>
                            <ListItem 
                                key={user.Id} 
                                secondaryAction={
                                    selectedWell.IsOwner && user.RoleId == 2 &&
                                    <IconButton edge="end" aria-label="add" onClick={() => removeUserAsMember(user)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                                className="well-member-list"
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <PersonIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={user.MemberId.FirstName + ' ' + user.MemberId.LastName + (user.MemberId.Suffix ? ' ' + user.MemberId.Suffix : '')}></ListItemText>
                            </ListItem>
                            <Divider component="li" />
                        </Fragment>
                        
                    ))}
                </List>
            </Grid>
        </Grid>
    );
  };
  
export default WellMembers;