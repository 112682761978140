import * as yup from 'yup';
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Container, Stack } from '@mui/material';
import { useAxiosApi } from '../../axios/useAxiosApi';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import {ReactComponent as Logo } from '../../logo.svg'; // Replace with Application Logo

const defaultValues = {
    email: ''
};

const validationSchema = yup.object({
    email: yup.string().required("Please enter an email.").email("Please enter a valid email.")
});

const ForgotPassword = () => {

    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors }, getValues } = methods;
    
    const [{ data: resetEmailData, loading: resetEmailLoading, error: resetEmailError, response: resetEmailResponse }, executeResetEmail] = useAxiosApi(
        {
            url: '/auth/password/request',
            method: 'POST'
        },
        {
            manual: true
        }
    )

    function resetEmail(form) {
        executeResetEmail({
            data: {
                email: form.email,
                reset_url: "http://localhost:3000/resetPassword" // TODO: move in config
            }
        })
    }

    return (
        <Container maxWidth="xs" sx={{marginTop: 5}}>
            <Logo className="logo" />
            <h1>Forgot Password</h1>
            <form className="forgotPassword" onSubmit={ handleSubmit(resetEmail)}>
                <Stack spacing={2}>
                    <FormTextField
                            name="email" 
                            control={control} 
                            label="Email" 
                            type="text"
                            variant="outlined"
                            error={errors?.email != null}
                            helperText={errors?.email?.message}
                    />
                    <LoadingButton loading={resetEmailLoading} type="submit" variant="contained">Send</LoadingButton>
                </Stack>
            </form>
            <>{ resetEmailError?.response?.data && <Alert severity="error">{resetEmailError?.response?.data}</Alert> }</>
            {resetEmailResponse?.status == 204 && <Alert severity="success">An email to reset your password has been sent. Return to <Link component={RouterLink} to={{pathname: "/login" }} underline="none">login</Link>.</Alert> }
        </Container>
        
    );
};

export default ForgotPassword;