import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, OutlinedInput, Select, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

export const FormTextField = ({ name, control, label, variant, type, required, error, helperText, size, inputProps, multiline, startAdornment, fullWidth }) => {
  return (
    <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
            <TextField multiline={multiline} inputProps={inputProps} InputProps={{value: value, startAdornment: startAdornment}} onChange={onChange} value={value} label={label} variant={variant} type={type} required={required} error={error} helperText={helperText} size={size} fullWidth={fullWidth} />
        )}
    />
  );
};

export const FormAutoComplete = ({ name, control, options, label, variant, type, required, error, helperText, size, freeSolo, getOptionLabel, renderOption, isOptionEqualToValue }) => {
  return (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <Autocomplete
              {...field}
              onChange={(_, data) => field.onChange(data)}
              options={options}
              getOptionLabel={getOptionLabel}
              renderOption={renderOption}
              freeSolo={freeSolo}
              renderInput={(params) => 
                <TextField {...params} label={label} variant={variant} type={type} required={required} error={error} helperText={helperText} size={size} />
              }
              onInputChange={(_, data) => {
                if (data) field.onChange(data);
              }}
              isOptionEqualToValue={isOptionEqualToValue}
            />
        )}
    />
  );
};

export const FormSelect = ({ name, control, label, variant, required, error, size, multiple, renderValue, selectRender, onChange, fullWidth }) => {
  return (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth={fullWidth}>
            <InputLabel>{label}</InputLabel>
            <Select
              {...field}
              multiple={multiple}
              onChange={onChange}
              variant={variant}
              required={required}
              error={error}
              size={size}
              input={<OutlinedInput label={label} />}
              renderValue={renderValue}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                }
              }}
            >
              {selectRender}
            </Select>
          </FormControl>
          
        )}
    />
  );
};

export const FormCheckbox = ({ name, control, label, size }) => {
  return (
    <FormControlLabel
      control={
        <Controller
            name={name}
            control={control}
            render={({ field: props }) => (
              <Checkbox
                {...props}
                checked={!!props.value}
                onChange={(e) => props.onChange(e.target.checked)}
                size={size}
              />
            )}
        />
      }
      label={label}
    />
  );
};

export default { FormAutoComplete, FormTextField }