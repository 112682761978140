import { Pageview as PageviewIcon } from "@mui/icons-material";
import { Chip, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Fragment, useContext } from "react";
import ItemsDashboardContext from "./ItemsDashboardContext";

const Row = (props) => {
  const { item } = props;
  const { setSelectedItem } = useContext(ItemsDashboardContext);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const prices = item.Stores.map((store) => store.Price).filter((price) => price != null);
  let priceRange = '';
  if(prices.length > 0) {
    const maxPrice = Math.max(...prices);
    const minPrice = Math.min(...prices);

    priceRange = minPrice == maxPrice ? currencyFormatter.format(minPrice) : currencyFormatter.format(minPrice) + ' - ' + currencyFormatter.format(maxPrice)
  } else {
    priceRange = '-'
  }
  
  const wells = item.Groups.map((group) => ({ Id: group.GroupId.Id, Name: group.GroupId.Name }));

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} hover>
        <TableCell component="th" scope="row">
          {item.Name}
        </TableCell>
        <TableCell align="left">
          <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1 }}>
            {wells.map((well) => (
              <Chip key={well.Id} label={well.Name} size="small" />
            ))}
          </Stack>
        </TableCell>
        <TableCell align="center">{priceRange}</TableCell>
        <TableCell align="center">{item.Quantity}</TableCell>
        <TableCell align="center">
            <IconButton
                aria-label="openItem"
                onClick={() => setSelectedItem(item)}>
                <PageviewIcon color="secondary" />
            </IconButton>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

const ItemsList = () => {
  const { items} = useContext(ItemsDashboardContext);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
      <Table aria-label="collapsible table" size="small" stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell>Item Name</TableCell>
            <TableCell align="left">Wells</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <Row key={item.Id} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
  
export default ItemsList;