import { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { useAxiosApi } from '../../axios/useAxiosApi';
import SharedItemsDashboardContext from './SharedItemsDashboardContext';
import authZConfig from '../../authZ/authZ.config';
import GlobalStateContext from '../../GlobalStateContext';

const SharedItemsUserSelector = () => {
    const { selectedUserId, setSelectedUserId } = useContext(SharedItemsDashboardContext);
    const { appUserId } = useContext(GlobalStateContext);
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState('');

    const handleChange = (event) => {
        setUser(event.target.value);
        setSelectedUserId(event.target.value);
    };

    const [{ data: getUsersData, loading: getUsersLoading, error: getUsersError, response: getUsersResponse }, executeGetUsers] = useAxiosApi(
        {
            url: '/items/Users',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    useEffect(() => {
        executeGetUsers({
            params: {
                "filter[_and][0][Groups][GroupId][Members][MemberId][_eq]": appUserId,
                "filter[_and][1][Id][_neq]": appUserId,
                "fields": "Id,FirstName,LastName,Suffix",
                "sort[]": "FirstName"
            }
        })
    }, [])

    useEffect(() => {
        if(getUsersData != null) {
            setUsers(getUsersData.data);
        }
    }, [getUsersData])

    useEffect(() => {
        if(users != null && users.length > 0) {
            const firstUser = users[0];
            setUser(firstUser.Id);
            setSelectedUserId(firstUser.Id);
        }
    }, [users])

    return (
    
        <Box sx={{ minWidth: 300 }}>
            <FormControl fullWidth>
                <InputLabel id="item-user-select-label">User</InputLabel>
                <Select
                    labelId="item-user-select-label"
                    id="item-user-select"
                    value={user}
                    label="User"
                    onChange={handleChange}
                >
                    { users && users.map((user) => { return <MenuItem key={user.Id} value={user.Id}>{user.FirstName} {user.LastName}{ user.Suffix ? ' ' + user.Suffix : ''}</MenuItem> } )}
                </Select>
            </FormControl>
        </Box>

    );
  };
  
export default SharedItemsUserSelector;