import * as yup from 'yup';
import { Alert, Box, Button, Snackbar, Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import GlobalStateContext from "../../GlobalStateContext";
import useYupValidationResolver from "../../validation/useYupValidationResolver";
import { FormTextField } from '../../form/MuiHookForm';
import { useAxiosApi } from '../../axios/useAxiosApi';
import LoadingButton from '@mui/lab/LoadingButton';
import WellsContext from './WellsContext';

const validationSchema = yup.object({
    name: yup.string().required('Please enter a name.').max(30, 'Must be no more than 30 characters.'),
    description: yup.string().max(200, 'Must be no more than 200 characters.')
});

const WellEdit = () => { 
    const {selectedWell, setSelectedWell, setWells, setWellEditing} = useContext(WellsContext);
    const defaultValues = {
        name: selectedWell.Name,
        description: selectedWell.Description
    };

    const { appUserId } = useContext(GlobalStateContext);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const resolver = useYupValidationResolver(validationSchema);
    const methods = useForm({ defaultValues: defaultValues, resolver: resolver });
    const { handleSubmit, control, formState: { errors } } = methods;

    const [{ data: editWellData, loading: editWellLoading, error: editWellError, response: editWellResponse }, executeEditWell] = useAxiosApi(
        {
            method: 'PATCH'
        },
        {
            manual: true
        }
    )

    const [{ data: getWellsData, loading: getWellsLoading, error: getWellsError, response: getWellsResponse }, executeGetWells] = useAxiosApi(
        {
            url: '/items/GroupMembers',
            method: 'GET'
        },
        {
            manual: true
        }
    )

    const callGetWells = function() {
        executeGetWells({
            params: {
                "filter[MemberId][_eq]": appUserId,
                "fields": "GroupId.Id,GroupId.Name,GroupId.Description,RoleId"
            }
        })
    }

    function editWell(form) {
        executeEditWell({
            url: '/items/Groups/' + selectedWell.Id,
            data: {
                Name: form.name,
                Description: form.description
            }
        })
    }

    useEffect(() => {
        if (editWellData != null) {
            setOpenSnackBar(true);
            callGetWells();
        }
    }, [editWellResponse])

    useEffect(() => {
        if(getWellsData != null) {
            const mappedWells = getWellsData.data.map((well) => {
                return {Id: well.GroupId.Id, Name: well.GroupId.Name, Description: well.GroupId.Description, IsOwner: well.RoleId == 1 ? true : false }
            });
            setWells(mappedWells);
            setSelectedWell(mappedWells.filter((well) => well.Id == selectedWell.Id)[0]);
            setWellEditing(false);
        }
    }, [getWellsData])

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackBar(false);
    };
    
    return (
    <Box>
        <form className="editWell" onSubmit={ handleSubmit(editWell)}>
            <Stack direction={{md: "row", xs: "column"}} spacing={1}>
                <FormTextField
                    name="name" 
                    control={control} 
                    label="Name" 
                    type="text"
                    variant="outlined"
                    error={errors?.name != null}
                    helperText={errors?.name?.message}
                    size="small"
                />

                <FormTextField
                    name="description" 
                    control={control} 
                    label="Description" 
                    type="text"
                    variant="outlined"
                    error={errors?.description != null}
                    helperText={errors?.description?.message}
                    size="small"
                />
                <LoadingButton loading={editWellLoading} type="submit" variant="contained">Save</LoadingButton>
                <Button color="secondary" variant="contained" onClick={() => setWellEditing(false)}>Cancel</Button>
            </Stack>
        </form>
        {editWellError?.response?.data.errors.map((error) => {
            return <Alert severity="error">{ error.message }</Alert>;
        })}
        {editWellError?.response?.data.errors == null && editWellError?.response?.data && <Alert severity="error">{JSON.stringify(editWellError?.response?.data)}</Alert>}
        {editWellResponse?.status == 204 && <Alert severity="success">Well updated.</Alert> }

        <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={openSnackBar} autoHideDuration={6000} onClose={handleSnackBarClose}>
            <Alert onClose={handleSnackBarClose} severity="success" sx={{ width: '100%' }}>Well updated.</Alert>
        </Snackbar>
    </Box>
  );
};
  
export default WellEdit;