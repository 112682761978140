import { Close as CloseIcon, Edit as EditIcon } from "@mui/icons-material";
import { Box, Card, CardContent, Container, Dialog, Divider, Grid, IconButton, Slide, Typography } from "@mui/material";
import { useState, forwardRef, useContext, useEffect } from "react";
import { useAxiosApi } from "../../axios/useAxiosApi";
import WellEdit from "./WellEdit";
import WellMemberAdd from "./WellMemberAdd";
import WellMembers from "./WellMembers";
import WellMembersContext from "./WellMembersContext";
import WellsContext from "./WellsContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WellDetailsDialog = () => {
  const {selectedWell, setSelectedWell, wellEditing, setWellEditing} = useContext(WellsContext);
  const [wellMembers, setWellMembers] = useState([]);
  const [open, setOpen] = useState(selectedWell != null);
  const [wellMembersUpdated, setWellMembersUpdated] = useState(false);
  
  const [{ data: getWellMembersData, loading: getWellMembersLoading, error: getWellMembersError, response: getWellMembersResponse }, executeGetWellMembers] = useAxiosApi(
    {
        url: '/items/GroupMembers',
        method: 'GET'
    },
    {
        manual: true
    }
  )

  const callGetWellMembers = function() {
    executeGetWellMembers({
        params: {
            "fields": "Id,MemberId.*,RoleId",
            "filter[GroupId][_eq]": selectedWell.Id
        }
    })
  } 

  const handleClose = () => {
    setOpen(false);
    setSelectedWell(null);
    setWellEditing(false);
  };

  const handleEdit = () => {
    setWellEditing(true);
  };

  useEffect(() => {
    callGetWellMembers();
  },[wellMembersUpdated])

  useEffect(() => {
    if (getWellMembersData != null) {
      setWellMembers(getWellMembersData.data);
    }
  },[getWellMembersData])


  return (
    <WellMembersContext.Provider value={{ wellMembers, wellMembersUpdated, setWellMembersUpdated }}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Box className="dialog-box-bar">
          <IconButton
            edge="start"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
        <Container>
          <Box mt={5}>
            {!wellEditing &&
              <Grid container justifyContent="start" alignItems="center">
                <Grid container item alignItems="center">
                  <Grid item>
                    <Typography variant="h3" sx={{ marginRight: 2}}>{ selectedWell.Name }</Typography>
                  </Grid>
                  { selectedWell.IsOwner &&
                    <Grid item>
                      <IconButton
                        edge="start"
                        onClick={handleEdit}
                        aria-label="edit"
                        color="secondary"
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  } 
                </Grid>
                <Grid item>
                  <Typography variant="h6" sx={{ marginRight: 2}}>{ selectedWell.Description }</Typography>
                </Grid>
              </Grid>
            }
            {wellEditing &&
              <Card sx={{marginBottom: 3}}>
                <CardContent>
                  <Grid>
                    <Grid item>
                      <h3>Edit Well Information</h3>
                    </Grid>
                      <Grid item>
                        <WellEdit />
                      </Grid>
                  </Grid>
                </CardContent>
              </Card>
            }
            <Card>
              <CardContent>
                <Grid container mt={2}>                  
                  <Grid container item spacing={2}>
                    {selectedWell.IsOwner && 
                      <Grid item xs={12} md={6}>
                        <WellMemberAdd />
                      </Grid>
                    }
                      <Grid item xs={12} md={6}>
                        <WellMembers />
                      </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Dialog>
    </WellMembersContext.Provider>
  );
};
  
export default WellDetailsDialog;