import DefaultLogin from './DefaultLogin';
import ProviderLogin from './ProviderLogin';
import authZConfig from '../../authZ/authZ.config';
import { Chip, Container, Divider, Stack } from '@mui/material';
import {ReactComponent as Logo } from '../../logo.svg'; // Replace with Application Logo

const Login = () => {
    return (
      <Container maxWidth="xs" sx={{marginTop: 5}}>
        <Stack spacing={4}>
          <Logo className="logo" />
          <div>{ authZConfig.defaultLoginEnabled && <DefaultLogin /> }</div>
          { 
            authZConfig.defaultLoginEnabled && authZConfig.providerLoginEnabled && 
            <Divider>
              <Chip label="OR" />
            </Divider>
          }
          <div>{ authZConfig.providerLoginEnabled && <ProviderLogin /> }</div>
        </Stack>
      </Container>
    );
  };
  
  export default Login;